export const mBrandIds = [
    {
        "label": "Abilene Christian University",
        "value": "11111"
    },
    {
        "label": "All-State Career School",
        "value": "11112"
    },
    {
        "label": "Ashford University",
        "value": "11113"
    },
    {
        "label": "Cogswell College",
        "value": "11114"
    },
    {
        "label": "CollegeAmerica",
        "value": "11115"
    },
    {
        "label": "ECPI University",
        "value": "11116"
    },
    {
        "label": "Everglades University",
        "value": "11117"
    },
    {
        "label": "Florida Career College",
        "value": "11118"
    },
    {
        "label": "Fortis College",
        "value": "11119"
    },
    {
        "label": "Full Sail University - Online",
        "value": "11120"
    },
    {
        "label": "Grand Canyon University",
        "value": "11121"
    },
    {
        "label": "Grantham University",
        "value": "11122"
    },
    {
        "label": "Gwinnett College",
        "value": "11123"
    },
    {
        "label": "Independence University",
        "value": "11124"
    },
    {
        "label": "Indiana Wesleyan University",
        "value": "11125"
    },
    {
        "label": "Institute of Technology",
        "value": "11126"
    },
    {
        "label": "Johnson & Wales University",
        "value": "11127"
    },
    {
        "label": "Keiser University",
        "value": "11128"
    },
    {
        "label": "Liberty University",
        "value": "11129"
    },
    {
        "label": "Lincoln Tech",
        "value": "11130"
    },
    {
        "label": "Los Angeles Pacific University",
        "value": "11131"
    },
    {
        "label": "Mid America Christian University",
        "value": "11132"
    },
    {
        "label": "Miller-Motte College",
        "value": "11133"
    },
    {
        "label": "Northcentral University",
        "value": "11134"
    },
    {
        "label": "Notre Dame College",
        "value": "11135"
    },
    {
        "label": "Penn Foster Career School",
        "value": "11136"
    },
    {
        "label": "Penn Foster College",
        "value": "11137"
    },
    {
        "label": "Peru State College",
        "value": "11138"
    },
    {
        "label": "Platt College",
        "value": "11139"
    },
    {
        "label": "Point Park University",
        "value": "11140"
    },
    {
        "label": "Post University",
        "value": "11141"
    },
    {
        "label": "Purdue University Global",
        "value": "11142"
    },
    {
        "label": "Rasmussen College",
        "value": "11143"
    },
    {
        "label": "Regent University",
        "value": "11144"
    },
    {
        "label": "Saint Leo University",
        "value": "11145"
    },
    {
        "label": "South Texas Vocational Technical Institute",
        "value": "11146"
    },
    {
        "label": "Southern Careers Institute",
        "value": "11147"
    },
    {
        "label": "Southern New Hampshire University",
        "value": "11148"
    },
    {
        "label": "Strayer University",
        "value": "11149"
    },
    {
        "label": "Sullivan University",
        "value": "11150"
    },
    {
        "label": "The Chicago School of Professional Psychology",
        "value": "11151"
    },
    {
        "label": "The College of Health Care Professions",
        "value": "11152"
    },
    {
        "label": "UEI College",
        "value": "11153"
    },
    {
        "label": "Ultimate Medical Academy",
        "value": "11154"
    },
    {
        "label": "University of the Cumberlands",
        "value": "11155"
    },
    {
        "label": "University of the People",
        "value": "11156"
    },
    {
        "label": "Vista College",
        "value": "11157"
    },
    {
        "label": "Walden University",
        "value": "11158"
    },
    {
        "label": "Compare Top Schools",
        "value": "11159"
    },
    {
        "label": "Allen school of Health Sciences",
        "value": "11160"
    },
    {
        "label": "Alvernia University",
        "value": "11161"
    },
    {
        "label": "American Career College",
        "value": "11162"
    },
    {
        "label": "Bryant & Stratton College",
        "value": "11163"
    },
    {
        "label": "Carrington College",
        "value": "11164"
    },
    {
        "label": "Campbellsville University",
        "value": "11165"
    },
    {
        "label": "CBD College",
        "value": "11166"
    },
    {
        "label": "Charter College",
        "value": "11167"
    },
    {
        "label": "College-Matcher",
        "value": "11168"
    },
    {
        "label": "Colorado Christian University",
        "value": "11169"
    },
    {
        "label": "Columbia Southern University",
        "value": "11170"
    },
    {
        "label": "Concordia University - St. Paul",
        "value": "11171"
    },
    {
        "label": "CyberTex Institute of Technology",
        "value": "11172"
    },
    {
        "label": "East Central University",
        "value": "11173"
    },
    {
        "label": "Eastern Oregon University",
        "value": "11174"
    },
    {
        "label": "Florida Technical College",
        "value": "11175"
    },
    {
        "label": "King University",
        "value": "11177"
    },
    {
        "label": "Saint Paul's School of Nursing",
        "value": "11178"
    },
    {
        "label": "Saybrook University",
        "value": "11179"
    },
    {
        "label": "Shawnee State University",
        "value": "11180"
    },
    {
        "label": "South University",
        "value": "11181"
    },
    {
        "label": "Thomas Jefferson University",
        "value": "11182"
    },
    {
        "label": "University of Arizona Global Campus",
        "value": "11183"
    },
    {
        "label": "ClassesUSA",
        "value": "11184"
    },
    {
        "label": "Point University",
        "value": "11185"
    },
    {
        "label": "OnlineColleges",
        "value": "11186"
    },
    {
        "label": "School Connection",
        "value": "11187"
    },
    {
        "label": "Education Connection",
        "value": "11188"
    },
    {
        "label": "Flatiron School",
        "value": "11193"
    },
    {
        "label": "Udemy",
        "value": "11194"
    },
    {
        "label": "Florida Tech",
        "value": "11195"
    },
    {
        "label": "Trident University International",
        "value": "11196"
    },
    {
        "label": "South College",
        "value": "11197"
    },
    {
        "label": "Berkeley College",
        "value": "11198"
    },
    {
        "label": "Western Governors University",
        "value": "11199"
    },
    {
        "label": "YTI Career Institute",
        "value": "11200"
    },
    {
        "label": "All-Star Directories",
        "value": "11201"
    },
    {
        "label": "American Intercontinental University",
        "value": "11202"
    },
    {
        "label": "Averett University",
        "value": "11203"
    },
    {
        "label": "Babson College",
        "value": "11204"
    },
    {
        "label": "Become A Nurse Online",
        "value": "11205"
    },
    {
        "label": "California Baptist University",
        "value": "11206"
    },
    {
        "label": "CareerStep.com",
        "value": "11207"
    },
    {
        "label": "Central Michigan University",
        "value": "11208"
    },
    {
        "label": "ChooseCollegeOnline.com",
        "value": "11209"
    },
    {
        "label": "ClassesUSA.com",
        "value": "11210"
    },
    {
        "label": "College Factual",
        "value": "11211"
    },
    {
        "label": "Colorado Technical University",
        "value": "11212"
    },
    {
        "label": "Cox School of Business at Southern Methodist University",
        "value": "11213"
    },
    {
        "label": "Earn My Degree",
        "value": "11214"
    },
    {
        "label": "Excelsior College",
        "value": "11215"
    },
    {
        "label": "Grad Schools",
        "value": "11216"
    },
    {
        "label": "Hunter Business School",
        "value": "11217"
    },
    {
        "label": "Kern Valley Medical College",
        "value": "11218"
    },
    {
        "label": "Lincoln Technical Institute",
        "value": "11219"
    },
    {
        "label": "Medical Assisting Training Schools",
        "value": "11220"
    },
    {
        "label": "National Louis University",
        "value": "11221"
    },
    {
        "label": "Northeast Technical Institute",
        "value": "11222"
    },
    {
        "label": "OnlineColleges.com",
        "value": "11223"
    },
    {
        "label": "Penn Foster",
        "value": "11224"
    },
    {
        "label": "Porter and Chester Institute",
        "value": "11225"
    },
    {
        "label": "St. Catherine University",
        "value": "11227"
    },
    {
        "label": "The University of Arizona Global Campus",
        "value": "11228"
    },
    {
        "label": "Tufts University",
        "value": "11229"
    },
    {
        "label": "UMass Global",
        "value": "11230"
    },
    {
        "label": "University of Arkansas Grantham",
        "value": "11231"
    },
    {
        "label": "University of West Alabama",
        "value": "11232"
    },
    {
        "label": "Waldorf University",
        "value": "11233"
    },
    {
        "label": "eLearners",
        "value": "11235"
    },
    {
        "label": "The University of Texas at Austin",
        "value": "11236"
    },
    {
        "label": "WorkFirst Health",
        "value": "11237"
    },
    {
        "label": "Ohio Business College",
        "value": "11238"
    },
    {
        "label": "Houston Christian University",
        "value": "11239"
    },
    {
        "label": "Arizona State University Online",
        "value": "11240"
    }
]