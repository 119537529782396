import { Box, IconButton } from '@mui/material'
import React, { useState } from 'react'
import PictureAsPdfRoundedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button } from 'flowbite-react';
import { downloadReport } from '../../../Service/Publisher';
import { getLast5DigitNumberPublisherGroup } from '../../../Utils/utils';
const DownloadFileComponent = (props) => {

  const [downloadUrl,setDownloadUrl]=useState("")
  const handelDownload= async ()=>{
    //Make API call
    const userStore=JSON.parse(localStorage.getItem("userId"))
    const pubGroup=userStore.attributes["custom:group"]
    console.log("pubGroup", pubGroup)
    // const publisherId=getLast5DigitNumberPublisherGroup(pubGroup)
    const reqBody={
      file_name:props.fileName,
      // "user_id":userStore.username
    }
    console.log("reqBody", reqBody);
    const res=await downloadReport(reqBody);
    console.log(res,"res");
    setDownloadUrl(res.s3_file_url)
    const link = document.createElement('a');
    link.href = res.s3_file_url; // Received download URL from the API response
    link.setAttribute('download', 'filename.ext'); // Specify the filename and extension

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);

  }
  return (
   <Box className='download_btn_container'>
     

<IconButton aria-label="download" size="small"  disabled={props.itemStatus !=='Generated' && true} onClick={handelDownload}>


  <PictureAsPdfRoundedIcon   />

 
</IconButton>
   </Box>
  )
}

export default DownloadFileComponent