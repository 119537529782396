import React, { useEffect, useState } from 'react'
import ResponsiveDrawer from './Components/New Home/MMHome'
import { Auth } from 'aws-amplify'
import { Login } from '../Components/Auth/Login';
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from '../Utils/PrivateRoutes';
// import BasicTabs from './Components/Tabs/MMTabs';
// import PubCampaigns from './View/Publisher/Campaigns';
import { mmRoutes } from '../Utils/routes';
import ResetPassword from '../Components/Auth/ResetPassword'


const MMApp = () => {
 
return (
    <>
    
     <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route element={<PrivateRoutes />}>
        {mmRoutes.map((route, index)=>{
            return  <Route path={route.path} element={<route.element />} />
            })}
  
                    </Route>
        </Routes> 
    </>
  
  )
}

export default MMApp

