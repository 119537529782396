import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Auth } from "aws-amplify";
import { useEffect, useState } from 'react';
import LoadingSpinner from '../Components/Layout/Loader/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { getLast5DigitNumberPublisherGroup } from './utils';
import { getCampaign } from '../Store/Slice_reducer/publisherCampaignSlice';
const PrivateRoutes = () => {
  // let auth = {'token':false}
  const navigate = useNavigate()
  const [userJwt, setUserJwt] = useState('');
  const [loading, setLoading] = useState(true)
  const [group, setGroup] = useState()
  const [userInfo, setUserInfo] = useState()
  const { pathname } = useLocation()
  const userStore=useSelector((state)=>state.userData)
  const dispatch=useDispatch();
  const getCurrentAuthToken = async () => {

    try {
      const res = await Auth.currentSession()
      const userInfo = await Auth.currentUserInfo()
      let accessToken = res.getAccessToken()
      let jwt = accessToken.getJwtToken()
      console.log("userInfo", userInfo)
      let publisherId; 
      const pubGroup=userInfo.attributes["custom:group"]
      publisherId=getLast5DigitNumberPublisherGroup(pubGroup)
      const param={
  publisherID:publisherId,
  token:jwt
}

    await dispatch(getCampaign(param))
      setLoading(false)
      setUserJwt(jwt)
      setGroup(group)
      setUserInfo(userInfo)
    } catch (err) {
      if(pathname==="/resetPassword"){
        navigate(pathname)
      }else{
        navigate("/Login")
      }
      
    }


  }
  useEffect(() => {

    console.log("Private Route:::::......------>>>>>")
    getCurrentAuthToken()
  }, [])

  // console.log("userJwt", group)
  // console.log("userJwt bool", Boolean(userJwt))

  return (
    <>
      {loading ? <><LoadingSpinner /></> : userJwt ? <Outlet context={

        [userJwt, group, userInfo]
      } /> : <Navigate to='/login' />}
    </>

  )
}
export default PrivateRoutes;