import * as React from 'react';
// import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
// import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useOutletContext, useLocation} from 'react-router-dom'
import AccountMenu from '../Account Settings/AccountMenu';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import SummarizeSharpIcon from '@mui/icons-material/SummarizeSharp';
const drawerWidth = 240;

const drawerList=[
  {
    id:"1",
    name:'Campaign',
    to:'/campaigns',
    icon:<CampaignOutlinedIcon/>

  },
  {
    id:"2",
    name:'Reports',
    to:'/report',
    icon: <DashboardCustomizeOutlinedIcon/>
  },
  // {
  //   id:"3",
  //   name:'Predefined Reports',
  //   to:"/predefined-reports",
  //   icon:<SummarizeSharpIcon/>
  // }
]




function ResponsiveDrawer(props) {
  const { window } = props;
  const currentPath = useLocation().pathname;
  console.log("pathname",currentPath);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeLink, setActiveLink]=React.useState(drawerList.find(item=>item?.to===currentPath)?.id)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const [userJwt, group, userInfo] = useOutletContext();

  const handelLinkClick=(id)=>{
 setActiveLink(id)

  }

  // const findDefaultPage=drawerList.find(item=>item.to===currentPath)
  React.useEffect(()=>{
    if(currentPath==='/'){
         setActiveLink("2")
    }
  },[currentPath])

// const pageTitle=(currentPath==='/report' || currentPath==='/reports' || currentPath==='/')?"Dashboard":"Campaigns"
  const drawer = (
    <div >
             <Toolbar >
              <Box sx={{maxWidth:"140px"}}>
             <img src='/images/Media-Matchers-logo.svg' alt="mm-icon" className='h-14 w-full'  />
             </Box>
        </Toolbar>

        <List>
        {drawerList.map((text, index) => (
          <ListItem key={text.id} disablePadding>
            <Link to={{pathname:text.to, state:{
              data1:"someData"
            }}} className={`w-full ${(text.id === activeLink ? " active" : "")}`} onClick={()=>handelLinkClick(text.id)}  >
            <ListItemButton >  
              <ListItemIcon>
                {text.icon}
              </ListItemIcon>
              <ListItemText primary={text.name} />
            </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      
    
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
   
    <Box sx={{ display: 'flex'}}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor:"#00AAE3",
          boxShadow:"none",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
        }}
      >
        <Toolbar className='justify-between'>
          <IconButton
            color="#000"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={
              { mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        
                  <Typography  fontWeight='600' component='h4' variant='h5' color={"#00000"}>
            {
            
            currentPath==='/'?"REPORTS":currentPath.replace("/","").toLocaleUpperCase()
            }
          </Typography>
        
                  <AccountMenu  userInfo={userInfo}/>
          
         
         
        </Toolbar>
      </AppBar>
     
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            
          }}
          className='mm-sidebar'
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          className='mm-sidebar'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)`, } }}
      >
       
       <Toolbar />
   {props.children}
    
      </Box>
    </Box>
  
  );
}



export default ResponsiveDrawer;
 