import { Auth, API } from 'aws-amplify';
export const  loginUserService=async (email, password)=>{
  
    const response=await Auth.signIn(email, password)
  return response;
}

export const  logoutUserService=async (email, password)=>{
  
    await Auth.signOut();
}


export const  generateOtpService=async (userName)=>{
  let response;
  try{
    response=await Auth.forgotPassword(userName)
   }catch(error){
  console.log("error ", error )
  response=error
  }
  
  // const response=await Auth.forgotPassword(userName)
return response;
}



export const createAndConformPwdForNewUser=async(user, newPassword, userName)=>{
let response;
  try{
  response=await  Auth.completeNewPassword(
      user, // the Cognito User Object
      newPassword, // the new password
      { name: userName } // TO DO - NAME, has to be set to user's
    )
}catch(err){
  response=err
}

return response;
}


export  const  updateUser=async(userName)=> {
  const {
    idToken: { jwtToken },
  } = await Auth.currentSession();
  const myInit = {
    headers: {
      "X-COG-KEY": jwtToken,
    },
    body: {
      Username: userName,
      UserAttributes: [
        {
          Name: "email_verified",
          Value: "true",
        },
      ],
    },
  };
  API.put("MerchantData", "user", myInit).then((response) => {
    console.log(response);
  });
}