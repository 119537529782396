import React, { useCallback, useContext } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'

import { useDispatch,useSelector } from 'react-redux'
import { handelOTP } from '../../../Store/Slice_reducer/ForgetPasswordSlice'

export default function SecondStep() {
 const dispatch=useDispatch()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <TextField
          id="otp"
          label="Enter OTP which we sent to your registered mail id"
          fullWidth
          onChange={(e)=>{
            dispatch(handelOTP(e.target.value))
          }}
          className='password-text-input'
        />
        </Grid>
      </Grid>

    
    </>
  )
}