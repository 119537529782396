import React, { useEffect } from 'react'
import ResponsiveDrawer from '../../Components/New Home/MMHome'
import BasicTabs from '../../Components/Tabs/MMTabs'
import { useDispatch, useSelector } from 'react-redux';
import { getCampaign } from '../../../Store/Slice_reducer/publisherCampaignSlice';
import { Box } from '@mui/material';
import CircularLoader from '../../../Components/Layout/Loader';
import { getLast5DigitNumberPublisherGroup } from '../../../Utils/utils';
import { Chart, registerables} from 'chart.js';

Chart.register(...registerables);
export const PUBLISHER_ID ="83687"
const MM_PublisherReports = () => {
    const dispatch=useDispatch();
    const pubCampData = useSelector((state) => state.pubCampData);
  //  console.log("pubCampData", pubCampData)
    const {loading, campaigns, campaignsDropdown
    }=pubCampData

     let publisherId;
     const userStore=JSON.parse(localStorage.getItem("userId"))
        const pubGroup=userStore.attributes["custom:group"]
        publisherId=getLast5DigitNumberPublisherGroup(pubGroup)
  //    useEffect(() => {

  //      const param={
  //       publisherID:publisherId
  //      }
  //     // dispatch(getCampaign(param))
    
  //    }, [])
  //  console.log("campaigns", campaigns);
  //  console.log("loader", loading);

    return (
    <ResponsiveDrawer>
        {loading ? 
        <Box my={1} display="flex" justifyContent="center" alignItems="center" height="520px"><CircularLoader /></Box> 
       :<BasicTabs  tabsProps={{
        campaignsDropdown,
        publisherId        
       }}/>
    }
    </ResponsiveDrawer>
  )
}

export default MM_PublisherReports


