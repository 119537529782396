import React, { useEffect, useState, } from 'react'
import { Box, Button, Grid, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material'
import DateRangePicker from '../../../Components/Layout/DatePicker/DateRangePicker';
import MultipleSelectCheckBox from '../Multi select checkbox/MultiSelectCheckbox'
import { areaStudysMenuOptions } from '../../../Store/Slice_reducer/DashboardSlice'
import moment from 'moment/moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { generatePublisherRawReports, generatePublisherReportStatus } from '../../../Service/Publisher';
import { brandDropdownMenus, brandDropdownMenusData } from './ClickToLeads';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import SimpleTable from '../Table/SimpleTable';
import RefreshIcon from '@mui/icons-material/Refresh';

import RawPageData from '../../../Mock/Required JSON/Raw-Reports.json'
const DEFAULT_FILE_NAME="publisher reports"
brandDropdownMenusData.sort((a, b) => {
  const labelA = a.label.toUpperCase();
  const labelB = b.label.toUpperCase();

  if (labelA < labelB) {
    return -1;
  }

  if (labelA > labelB) {
    return 1;
  }

  return 0;
});


const   REPORT_TYPE=[
  {
    ID:"1",
    name:"Consumer Attribute Performance report"
  }
]
const RawReports = ({ dropDownMenus, publisher_id }) => {

  const [dateValue, setDateValue] = useState({
    // startDate: moment().startOf('day').toDate(),
    // endDate: moment().endOf('day').toDate(),
    startDate: null,
    endDate: null,

  })
  const [campaignsSelected, setCampaignsSelected] = useState(dropDownMenus.dashboardCampaigns.map(item => item.label))
  const [areaStudyselected, setAreaStudySelected] = useState(areaStudysMenuOptions.map(item => item.label))
  const [brandSelected, setbrandSelected] = useState(brandDropdownMenus)
  const selectedBrandIds = brandDropdownMenusData.filter(option => brandSelected.includes(option.label));
  const [loading, setLoading] = useState(true)
  const [loading1, setLoading1] = useState(false);
  const [open, setOpen] = useState(false)
  const [reportName, setReportName]=useState('')
  const [reportType, setReportType]=useState(REPORT_TYPE[0].name)
  // const [csvData, setCsvData] = useState([])
   const [error, setError] = useState("")
  const [statusTableData, setStatusTableData] = useState(RawPageData.reportStatusTable)
  const selectedCampaigns = dropDownMenus.dashboardCampaigns.filter(option => campaignsSelected.includes(option.label));
  const selectedAreaStudyIds = areaStudysMenuOptions.filter(option => areaStudyselected.includes(option.label));
  
  const [dateError, setDateError]=useState('')
  const [campaignsDDError, setCampaignDDError]=useState("")
  const [areaStudyDDError, setAreaStudyDDError]=useState("")
  const [mBrandDDError, setmBrandDDError]=useState("")

  const handelDateChange = React.useCallback((newValue) => {
    setDateValue(newValue)
    setDateError("")
  }, [])

  const handelRefreshButton = async () => {
    console.log("handelRefresh make API call!!!")
    // setLoading1(true)
    setLoading(true)
    const res = await generatePublisherReportStatus()
    console.log("res", res);
    setStatusTableData(res.mm_report_details)
    setLoading(false)
  }



  const handleCampaignsDropdowns = (value) => {
    // dispatch(handelReportsCampaignsDropdown({type:'raw', data:value }))
    setCampaignsSelected(value)
    if (value.length === 0){
      setCampaignDDError("Please select any one campaign")
    }else {
      setCampaignDDError("")
    }
  }

  const handelAreaStudyDropdown = (value) => {
    // dispatch(handelReportsAreaStudyDropdown({type:'raw', data:value }))
    setAreaStudySelected(value)
    if (value.length === 0){
      setAreaStudyDDError("Please select any one area study")
    }else {
       setAreaStudyDDError("")
    }
  }
  const handlemBrandsIDDropdown = (value) => {

    setbrandSelected(value)
    if (value.length === 0){
      setmBrandDDError("Please select any one brand")
    }else {
      setmBrandDDError("")
    }
  }

  const handleClose = () => {
    setOpen(false)
  };

const handelReportType=(value)=>{
  console.log("rt", value)
 setReportType(value)
}



  const handelGenerateReport = async () => {
    console.log("Start date ", dateValue)

    const queryParams = {
      startDate: moment(dateValue.startDate).format("YYYY-MM-DD"),
      endDate: moment(dateValue.endDate).format("YYYY-MM-DD"),
      mPubID: publisher_id,
      mPubCampaignID: selectedCampaigns.map(camp => camp.value).join(','),
      areaStudy: selectedAreaStudyIds.map(camp => camp.value).join(','),

      mBrandID: selectedBrandIds.map(camp => camp.value).join(','),
      reportType:reportType.split(' ').join("-"),
      reportFileName:reportName
    }
const {startDate, endDate}=dateValue
const {mPubCampaignID,mBrandID, areaStudy }=queryParams
  if(reportName && startDate && endDate && mPubCampaignID && mBrandID && areaStudy){
    setLoading1(true)
    setError("")
    setDateError("")
    let strArr =reportType.split(' ');
    console.log(strArr.join('-')," tr");
   

    const res = await makeRequestToGenerateReport(queryParams);

    console.log("query params ", queryParams)
  }else{
    if(!reportName){
      setError("Please provide report name")
    }
    if(!startDate || !endDate){
      setDateError("Please select date in between last previous 30 days") 
      if(reportName){
        setError("")
      }
    }
    
    
  }
    

  }

  const handleReportNameChange=(event)=>{
  const {value}=event.target;
  if(value){
    setReportName(value)
    setError("")
  }else{
    setError("Please provide report name")
    setReportName("")
  }
  
}

  const fetchReports = async () => {

   
    try{
      setLoading(true)
      const res = await generatePublisherReportStatus()
      console.log("res", res);
      setStatusTableData(res.mm_report_details)
      setLoading(false)
    }catch(err){
      setLoading(true)
      setStatusTableData([])
    }
    
  }



  useEffect(() => {
    fetchReports()
  }, [])



  const makeRequestToGenerateReport = async (queryParams) => {
    setLoading1(true)
    // setLoading(true)
    const response = await generatePublisherRawReports(queryParams);
    const res=await fetchReports()
    setLoading1(false)
    setOpen(true)
    // setTimeout(() => {
    //   fetchReports()
    
    // }, 2000)



    // parallel  API call
    // const [response , response1]=await Promise.all([
    //   generatePublisherRawReports(queryParams),
    //   fetchReports()
    // ])
    return response
  }




  return (
    <>

      <Box  className='px-0'>

        <Grid container spacing={3}>
          <Grid item xs="12" md="4" className="raw_page_filter_section">

            <DateRangePicker dateValue={dateValue} onChangeValue={handelDateChange}     maxDate={new Date()} minDate={new Date(new Date().getTime() - 29 * 24 * 60 * 60 * 1000)}/>
          {dateError.length>0 &&
            <Typography variant="subtitle2" align="center" color="error">{dateError}</Typography>}
          </Grid>
          <Grid item xs="12" md="4">
            <SelectReportsType reportTypeHandler={handelReportType} />

          </Grid> 

           <Grid item xs="12" md="4">

            <TextField
             required
              id="outlined-textarea"
              label="Create report name"
              placeholder="Please mention report name"
              className='password-text-input'
              sx={{ width: "100%" }}
              value={reportName}
              onChange={handleReportNameChange}
              defaultValue={DEFAULT_FILE_NAME}
              error={error.length>0}
              helperText={error}
            />

          </Grid> 
          <Grid item xs="12" md="4">
            <MultipleSelectCheckBox menuData={dropDownMenus.dashboardCampaigns.map(item => item.label)} menuLabel="Campaigns" menuSelected={campaignsSelected} menuHandler={handleCampaignsDropdowns} isError={campaignsDDError.length>0}  errorMessage={campaignsDDError} />
          </Grid>
          <Grid item xs="12" md="4">
            <MultipleSelectCheckBox menuData={areaStudysMenuOptions.map(item => item.label)} menuLabel="Area Study" menuSelected={areaStudyselected} menuHandler={handelAreaStudyDropdown}   isError={areaStudyDDError.length>0}  errorMessage={areaStudyDDError}/>
          </Grid>
          <Grid item xs="12" md="4">
            <MultipleSelectCheckBox menuData={brandDropdownMenus} menuLabel="Brands" menuSelected={brandSelected} menuHandler={handlemBrandsIDDropdown} isError={mBrandDDError.length>0}  errorMessage={mBrandDDError} />
          </Grid>

        </Grid>
         <Box className='btn_container' >
          <LoadingButton onClick={handelGenerateReport} loadingPosition="center" loading={loading1}>Generate Report</LoadingButton>
        </Box> 

         <Box my={5} className='raw_status_table_container'>
          <Paper className='report_table_container'>
            <Box className='refresh-button'>
              <Button endIcon={<RefreshIcon />} onClick={handelRefreshButton}>
                Refresh
              </Button>
            </Box>
            <Box >
              {
                loading ? <Box>
                  <Skeleton variant="wave" height={'30vh'} />
                </Box> : <SimpleTable tableContent={statusTableData} />
              }

            </Box>
          </Paper>
        </Box> 


        <Stack spacing={2} sx={{ maxWidth: 600 }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={handleClose}
            message={
              'Your request to generate the report has been submitted. Please find the export status in the table below.'
            }
          // key={vertical + horizontal}
          />
        </Stack>

      </Box>

    </>

  )
}

export default RawReports





function SelectReportsType(props) {
  const [reportType, setReportType] = React.useState(REPORT_TYPE[0].ID);

  const handleChange = (event) => {
    setReportType(event.target.value);
    const name=REPORT_TYPE.filter((list)=>{ 
          return list.ID===event.target.value
    })
    console.log(name, "name")
    // let str = "Consumer Report User Type"



    props.reportTypeHandler(name[0].name)
  };

  return (
    <Box>
      <FormControl sx={{ minWidth: "100%" }}>
        <InputLabel id="demo-simple-select-helper-label">Select Report Type</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={reportType}
          label="Select Report Type"
          onChange={handleChange}
        >
          {
            REPORT_TYPE.map((list)=>{
             return  <MenuItem value={list.ID} key={list.ID}>{list.name}</MenuItem>
            })
          }
          {/* <MenuItem value={10}>Consumer Attribute Performance report</MenuItem>
          <MenuItem value={20}>More Raw Data Reports may be available in the future</MenuItem> */}
        </Select>
      </FormControl>
    </Box>
  );
}
