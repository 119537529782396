import React, { useCallback, useContext, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
// import { AppContext } from '../Context'
import {handelErrors, handelUserName} from '../../../Store/Slice_reducer/ForgetPasswordSlice'
import { useDispatch, useSelector } from 'react-redux'
export default function FirstStep() {
  // const { formValues, handleChange, handleNext, variant, margin } = useContext(AppContext)
  // const { firstName, lastName, email, gender } = formValues

  // // Check if all values are not empty and if there are some errors
  // const isError = useCallback(
  //   () =>
  //     Object.keys({ firstName, lastName, email, gender }).some(
  //       (name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
  //     ),
  //   [formValues, firstName, lastName, email, gender]
  // )
  const pageState=useSelector((state)=>state.forgetPassWordPageData)
const dispatch=useDispatch();
const handleChange=(v)=>{

    dispatch(handelUserName(v.target.value))
    setUserName(v.target.value)
  
 
}
  const [userName, setUserName]=useState('')
  console.log("userN", userName)
  return (
    <>
      
        <Grid item xs={12} md={4}sm={6}>
          <TextField
            // variant={variant}
            // margin={margin}
            fullWidth
            label='User Name'
            name='userName'
            placeholder='Enter your name'
            value={pageState.userName}
            onChange={handleChange}
            error={pageState.formErrors.userNameError.length>0}
            helperText={pageState.formErrors.userNameError}
            required={true}
            className='password-text-input'
          />
        </Grid>
      
      

      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant='contained'
          sx={{ mt: 3, ml: 1 }}
          disabled={isError()}
          color='primary'
          onClick={!isError() ? handleNext : () => null}
        >
          Next
        </Button>
      </Box> */}
    </>
  )
}