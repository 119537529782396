import moment from "moment";

export function getHyphenValues(str) {
    const firstHyphenIndex = str.indexOf('-');
    const secondHyphenIndex = str.indexOf('-', firstHyphenIndex + 1);
  
    const firstValue = str.substring(0, firstHyphenIndex);
    const secondValue = str.substring(firstHyphenIndex + 1, secondHyphenIndex);
  
    return secondValue;
  }

  export  function getDates(startDate, endDate) {
    let dates = [];
    let currentDate = new Date(startDate);
    endDate = new Date(endDate);
  // diiffDays(currentDate,endDate)
  
    // check is that same date or not
    if (currentDate.getFullYear() === endDate.getFullYear() &&
      currentDate.getMonth() === endDate.getMonth() &&
      currentDate.getDate() === endDate.getDate()) {
    // console.log('The two dates represent the same day.');
     return dates=["12AM", "1AM","2AM","3AM", "4AM","5AM"]
  
  }
    while (currentDate <= endDate) {
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const year = currentDate.getFullYear().toString();
      const dateStr = `${day}-${month}-${year}`;
      dates.push(dateStr);
  
      currentDate.setDate(currentDate.getDate() + 1); // increment by 7 days
    }
  
  
  
    return dates;
  }


  export const generateChartData=(data, label, type="bar")=>{
    // console.log("labels",label )
    const dataCopy={...data}
     dataCopy.labels=label
    const newData=dataCopy.datasets.map((item, index)=>{
          const item1={...item}
          item1.data=label.map(()=>
          {
            // console.log("hi")
          return type==="bar"?generateRandom():generate0to100()
          })
          return item1
    })
    // console.log("utile-->combo-->newdata before dataset", dataCopy,"Type", type)
    dataCopy.datasets=newData
    // console.log("utile-->combo-->newdata after dataset", dataCopy, "Type", type)
   return dataCopy
   }

   function generateRandom() {
    const randomNum = 100 + Math.random() * 1000;
    const num=`${Math.round(randomNum / 100) * 100}`
    return num;
  }

  export const generate0to100=()=>{
    const randomNumber = Math.floor(Math.random() * 100) + 1;

return randomNumber
  }


  export const filterTableBodyContent=(coreData, selectedData)=>{
    const filteredArray = coreData.filter(data => {
      
        const matchingLabel = selectedData?.find(item => item.label === data.campaignName );
        return matchingLabel;
      });
      return  filteredArray
    }


    export const filterRawDataBasedOnDate=(originalArray, dateObj)=>{
 const {startDate, endDate}=dateObj;
 const startDate1 = new Date(startDate).setUTCHours(0, 0, 0, 0);
  const endDate1 = new Date(endDate).setUTCHours(0, 0, 0, 0);

  const filteredArray = originalArray.filter((item) => {
    const itemDate = new Date(item.DATE).setUTCHours(0, 0, 0, 0);
    return itemDate >= startDate1 && itemDate <= endDate1;
  });

  console.log("fa", filteredArray);
  console.log("length", filteredArray.length);
  return filteredArray
    }


    export const createCampaignsDropdown=(campaigns)=>{
     return campaigns.map((campaign)=>{
        return {
           label:campaign.campaignname,
           value:campaign.SORTID

        }
      })
    }


    export function getDayDifference(start, end) {
      const diffInMs = end.getTime() - start.getTime();
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    
      if (diffInDays === 0) {
        return "intraday";
      } else if (diffInDays === 1) {
        return "yesterday";
      } else if (diffInDays <= 7) {
        return "week";
      } else if (diffInDays > 30) {
        return "month";
      } else {
        return "other";
      }
    }
    

    export function getTimePeriod1(start, end) {
      const MILLIS_PER_DAY = 86400000;
      const daysDiff = Math.floor((end - start) / MILLIS_PER_DAY);
      const weeksDiff = Math.floor(daysDiff / 7);
      const monthsDiff = Math.floor(daysDiff / 30);
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const yesterday = new Date(today - MILLIS_PER_DAY);
      const lastWeek = new Date(today - 7 * MILLIS_PER_DAY);
    
      if (daysDiff === 0) {
        return "intraday";
      } else if (daysDiff === 1) {
        return "yesterday";
      } else if (daysDiff >= 2 && daysDiff <= 7) {
        return "previous week";
      } else if (weeksDiff > 0 && monthsDiff > 0) {
        return `${monthsDiff} month(s) and ${weeksDiff % 4} week(s)`;
      } else if (weeksDiff > 0) {
        return `${weeksDiff} week(s)`;
      } else {
        return `${monthsDiff} month(s)`;
      }
    }


    export const sortByDate=(a, b)=>{
     const date1=new Date(a.Date);
     const date2=new Date(b.Date);
     return date1-date2
    }


    export function getLast5DigitNumberPublisherGroup(str) {
      const regex = /\d{5}$/; // match 5 consecutive digits at the end of the string
      const match = str.match(regex);
      return match ? match[0] : null;
    }


    /* 
     As per the requirement we need to show data based on dates each date represents each row
    */
   export  const modifyDashboardTableData=(data)=>{
      const result = [];
    const groupedData = {};
    
    // Group the data by date
    data.forEach((item) => {
      var momentDate = moment.utc(item['Date/Time']);
      const date = momentDate.format("MM-DD-YYYY")
      if (groupedData[date]) {
        groupedData[date].push(item);
      } else {
        groupedData[date] = [item];
      }
    });
    
    // Calculate the sum and average for each group
    for (const date in groupedData) {
      const group = groupedData[date];
      const sumClicks = group.reduce((total, item) => total + item.CLICKS, 0);
      const sumCommission = group.reduce((total, item) => total + item["Total Commission"], 0);
      const sumAdRequests = group.reduce((total, item) => total + item.Ad_Requests, 0);
      // const sumCPC = group.reduce((total, item) => total + item.CPC, 0);
      // const averageCTR = group.reduce((total, item) => total + item.CTR, 0) / group.length;
      const averagePosition = group.reduce((total, item) => total + item["Avg position"], 0) / group.length;
    
//     console.log("Sum Clicks", sumClicks)
//     console.log("Sum Commission", sumCommission)

// console.log("estimated cpc for all campaigns ", sumCommission/sumClicks)
// console.log("Clicks through rate", sumClicks/sumAdRequests)
const calculatedCPC=sumCommission/sumClicks
const calculatedCTR=(sumClicks/sumAdRequests)*100
      result.push({
        Date: date,
        Commission: `$${sumCommission.toFixed(2)}`,
        CPC:`$${calculatedCPC.toFixed(2)}`,
        AdRequests: sumAdRequests,
        CLICKS: sumClicks,
        "Click-Through-Rate": `${calculatedCTR.toFixed(2)}%`,
        "Average Position": averagePosition.toFixed(2)
      });
    }
    
    console.log(result);
    return result;
    
    }



    export  const modifyClicksToLeadTableData=(data)=>{
      const result = [];
    const groupedData = {};
    
    // Group the data by date
    data.forEach((item) => {
      var momentDate = moment.utc(item['Date']);
      const date = momentDate.format("MM-DD-YYYY")
      if (groupedData[date]) {
        groupedData[date].push(item);
      } else {
        groupedData[date] = [item];
      }
    });
    
    // Calculate the sum and average for each group
    for (const date in groupedData) {
      const group = groupedData[date];
      const sumClicks = group.reduce((total, item) => total + item.CLICKS, 0);
      const sumCommission = group.reduce((total, item) => total + item["Total Commission"], 0);
      const sumAdRequests = group.reduce((total, item) => total + item.Requests, 0);
      // const sumCPC = group.reduce((total, item) => total + item.CPC, 0);
      // const averageCTR = group.reduce((total, item) => total + item.CTR, 0) / group.length;
      const averageCTL = group.reduce((total, item) => total + item["CTL"], 0) / group.length;
      const sumLeads=group.reduce((total, item) => total + item.leads, 0);
      const calculatedCPC=sumCommission/sumClicks
      const calculatedCTR=(sumClicks/sumAdRequests)*100
     console.log("cpc in clicks-to-leads", calculatedCPC)
     console.log("ctr in clicks-to-leads", calculatedCTR)
      result.push({
        Date: date,
        Commission: `$${sumCommission.toFixed(2)}`,
        CPC:`$${calculatedCPC.toFixed(2)}`,
        AdRequests: sumAdRequests,
        CLICKS: sumClicks,
        Leads:sumLeads,
        "CTR": `${calculatedCTR.toFixed(2)}%`,
        "CTL": `${averageCTL.toFixed(2)}%`
      });
    }
    
    console.log(result);
    return result;
    
    }
  