import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPubDashboardReports, getPublisherRawReports } from '../../Service/Publisher';
import { mBrandIds } from '../../Mock/mBrandData';
import { getTimePeriod1 } from '../../Utils/utils';
export const getPubReports = createAsyncThunk("publisher/getDashboardReports", async (queryParams,{ getState }) => {
        const state = getState();
        console.log("state::::", state)

   let response =await getPubDashboardReports(queryParams);
    return response
    });
    
    export const getPubRawReports = createAsyncThunk("publisher/getDashboardRawReports", async (queryParams) => {
      console.log("params", queryParams)
     let response =await  getPublisherRawReports(queryParams);
      return response

      });
      

 export const areaStudysMenuOptions=[
  { value: "1", label: "Business & Finance" },
  { value: "2", label: "Criminal Justice & Legal Services" },
  { value: "3", label: "Psychology & Counseling" },
  { value: "4", label: "Computers & IT" },
  { value: "5", label: "Healthcare & Medical Assisting" },
  { value: "6", label: "Education & Teaching" },
  { value: "7", label: "Trade, Vocational, Career" },
  { value: "8", label: "Liberal Arts" },
  { value: "9", label: "General" },
  { value: "10", label: "Nursing" },


];

export const dashboardSlice = createSlice(
    {
  name: 'Reports data',
  initialState:{
    overall:{
      dateValue:{
        startDate:'01-01-2023',
        endDate: "31-01-2023"
    },
    timePeriod:{

    },
      campaigns:[

      ],
      areaStudySelected:[
          
      ],
      reportsRespone:{

      }
    },
    clickToLead:{
      dateValue:{
        startDate: new Date(),
        endDate: new Date().setHours(23, 59, 59, 999)
    },
        campaigns:[

        ],
        areaStudy:[
            
        ]
    },
    areaStudy:{
        dateValue:{
            
        },
        campaigns:[

        ],
        areaStudy:[
            
        ]
    },
    rawDataReport:{
      dateValue:{
        startDate:'2023-01-01',
        endDate: "2023-01-31"
    },
    timePeriod:{

    },
    selectedCampaigns:[],
        selectedAreaStudy:areaStudysMenuOptions,
      brandNames:mBrandIds,
      loading:null
    },
    dateValue:{
        startDate: new Date(),
        endDate: new Date().setHours(23, 59, 59, 999)
    },
    loading:null,
    campaignsDropdown:[]
  }
  ,
  reducers: {
    handelDateRangePicker: (state, action) => {
        const newData={...state}
        console.log("action payload", action)
       newData.dateValue=action.payload
       return {...state, dateValue:action.payload}

      ;
  },

 handelDatePicker:(state, action)=>{
  switch(action.payload.type){
    case "raw":
      const rawReport={...state.rawDataReport}
      console.log("action payload", action)
      const datePeriod=getTimePeriod1(new Date(action.payload.data.startDate) ,new Date(action.payload.data.endDate))
      console.log("action payload date period:::::::----->>>>>>", datePeriod)
      rawReport.timePeriod=datePeriod
      rawReport.dateValue=action.payload.data
 
     return {...state, rawDataReport:rawReport}
     case "overall":
      const overallReports={...state.overall}
      console.log("action payload", action)
      overallReports.dateValue=action.payload.data
 
     return {...state, overall:overallReports}
     case "click-to-lead":
      const clickToLead={...state.clickToLead}
      console.log("action payload", action)
      clickToLead.dateValue=action.payload.data
 
     return {...state, overall:clickToLead}
     case "areaStudy":
      const areaStudy={...state.areaStudy}
      console.log("action payload", action)
      areaStudy.dateValue=action.payload.data
 
     return {...state, overall:areaStudy}
     default :
     return state
  }
     
 },

  handelRawDataBrandNames:(state, action)=>{

    switch(action.payload.type){
      case "raw":
        const rawReport={...state.rawDataReport}
        console.log("action payload", action)
        rawReport.brandNames=action.payload.data
   
       return {...state, rawDataReport:rawReport}
       case "overall":
        const overallReports={...state.overall}
        console.log("action payload", action)
        overallReports.areaStudySelected=action.payload.data
   
       return {...state, overall:overallReports}
       case "click-to-lead":
        const clickToLead={...state.clickToLead}
        console.log("action payload", action)
        clickToLead.brandNames=action.payload.data
   
       return {...state, overall:clickToLead}
       case "areaStudy":
        const areaStudy={...state.areaStudy}
        console.log("action payload", action)
        areaStudy.brandNames=action.payload.data
   
       return {...state, overall:areaStudy}
       default :
       return state
    }
       
  },

  addCampaginsDropDownValue:(state, action)=>{
       return {...state, campaignsDropdown:action.payload}
  },
  handelRawDataCampaigns:(state, action)=>{
       return {...state,}
  },
  
  handelReportsCampaignsDropdown:(state,action)=>{
    console.log("action ", action)
    switch(action.payload.type){
      case "raw":
        console.log("action rraw camp dd ", action.payload.data)
        const rawReport={...state.rawDataReport}
          rawReport.selectedCampaigns=action.payload.data
       return {...state, rawDataReport:rawReport}
       case "overall":
        const overallReports={...state.overall}
      
        overallReports.selectedCampaigns=action.payload.data
   
       return {...state, overall:overallReports}
       case "click-to-lead":
        const clickToLead={...state.clickToLead}
        
        clickToLead.selectedCampaigns=action.payload.data
   
       return {...state, overall:clickToLead}
       case "areaStudy":
        const areaStudy={...state.areaStudy}
        
        areaStudy.selectedCampaigns=action.payload.data
   
       return {...state, overall:areaStudy}
       default :
       return state
    }
  },
  // handelReportsAreaStudyDropdown:(state,action)=>{
  //   console.log("action ", action)
  //   switch(action.payload.type){
  //     case "raw":
  //       const rawReport={...state.rawDataReport}
  //         rawReport.selectedAreaStudy=action.payload.data
  //      return {...state, rawDataReport:rawReport}
  //      case "overall":
  //       const overallReports={...state.overall}
      
  //       overallReports.selectedAreaStudy=action.payload.data
   
  //      return {...state, overall:overallReports}
  //      case "click-to-lead":
  //       const clickToLead={...state.clickToLead}
        
  //       clickToLead.selectedAreaStudy=action.payload.data
   
  //      return {...state, overall:clickToLead}
  //      case "areaStudy":
  //       const areaStudy={...state.areaStudy}
        
  //       areaStudy.selectedAreaStudy=action.payload.data
   
  //      return {...state, overall:areaStudy}
  //      default :
  //      return state
  //   }
  // }

},
extraReducers:{
    [getPubReports.fulfilled]: (state, action) => {     
        console.log("state ", state)
        const overallState={...state.overall}
        overallState.reportsRespone=action.payload
       return {...state,  overall:overallState, loading:false}
      },
      [getPubReports.pending]: (state) => {
        state.loading = true;
      },
      [getPubReports.rejected]: (state) => {
        state.loading = true;
      },
      [getPubRawReports.fulfilled]: (state, action) => {     
        console.log("state ", state)
        const rawReportState={...state.rawDataReport}
        rawReportState.reportsRespone=action.payload
        rawReportState.loading=false;
       return {...state,  rawDataReport:rawReportState}
      },
      [getPubRawReports.pending]: (state) => {
        state.loading = true;
        
        const rawReportState={...state.rawDataReport}
        
        rawReportState.loading=true;
        return {...state,  rawDataReport:rawReportState}
      },
      [getPubRawReports.rejected]: (state) => {
        // state.loading = true;
        const rawReportState={...state.rawDataReport}
        
        rawReportState.loading=true;
        return {...state,  rawDataReport:rawReportState}
      },
}


});

// this is for dispatch
export const { handelDateRangePicker, handelRawDataBrandNames , handelDatePicker, addCampaginsDropDownValue , handelReportsCampaignsDropdown ,handelReportsAreaStudyDropdown} = dashboardSlice.actions;

// this is for configureStore
export default dashboardSlice.reducer;
