

export const publisherReportPageTabsData=[
 {
    title:"Overall",
    id:'overall-tab',
   //  contentElement: OverallReport
 },
 {
    title:"Click-to-Lead",
    id:'Click-to-Lead-tab',
   //  contentElement:PublisherClickToLeadsReports
 },
//  {
//     title:"Area Study",
//     id:'Area Study-tab',
//     contentElement:PublisherAreaStudyReports
//  },
 {
    title:"Raw Data Report",
    id:'Raw Data Report',
   //  contentElement:PublisherRawData
 }
]

