import { Box, Grid, Paper, Typography, } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import MultipleSelectCheckBox from '../Multi select checkbox/MultiSelectCheckbox'
// import { createCampaignsDropdown } from '../../../Utils/utils';
import {  areaStudysMenuOptions, } from '../../../Store/Slice_reducer/DashboardSlice';

import { getPubDashboardOverallTableReports, getPubDashboardReports,} from '../../../Service/Publisher';
import DateRangePicker from '../../../Components/Layout/DatePicker/DateRangePicker';
import ReportsTable from '../Table/ReportsTable';
import PublisherReportJson from "../../../Mock/publisherReport.json"
import { Bar } from 'react-chartjs-2';
import moment from 'moment/moment';
import {modifyDashboardTableData, sortByDate } from '../../../Utils/utils';
import { createBarChartJson, overallPageBarOptions } from '../../../Utils/chartsDs';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PUBLISHER_ID } from '../../View/Publisher/MM_PublisherReports';
import Skeleton from '@mui/material/Skeleton';
import 'moment-timezone';

export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

const aggregateTableData=(data)=>{
  const result = data.reduce((acc, obj) => {
      const date =  moment(obj['Date/Time']).format("MM-DD-YYYY")// Extract the date from the timestamp
      const existingIndex = acc.findIndex(item => item.date === date);
      if (existingIndex !== -1) {
        // If the date already exists in the accumulator, update the values
        acc[existingIndex].commission += obj['Total Commission'];
        acc[existingIndex].clicks += obj['CLICKS'];
        acc[existingIndex].cpc += obj['CPC'];
        acc[existingIndex].ctr += obj['CTR']
        acc[existingIndex].Average_position += obj['Avg position']
        // acc[existingIndex].Average_position += obj['Avg position']


      } else {
        // Otherwise, create a new object in the accumulator with the values
        acc.push({
          date,
          commission: obj['Total Commission'],
          clicks: obj['CLICKS'],
          cpc: obj['CPC'],
          ctr:obj['CTR'],
          Average_position: obj['Avg position']
        });
      }
      return acc;
    }, []);
    
    console.log("output", result);
    // Convert the array of objects to an array of strings
    const output = result.map(obj => {
      return `${obj.date}: CPC=${obj.cpc.toFixed(2)}, 
          Clicks=${obj.clicks}, Commission=${obj.commission.toFixed(2)}`;
    });
    
    console.log("output", output);
  
}

const Dashboard = (props) => {
  const { dropDownMenus , publisher_id} = props
  const [rawReportResponse, setRawReportResponse] = useState([])
  const [barChartData, setBarCharts] = useState(PublisherReportJson.chartData);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(true);

  const [dateValue, setDateValue] = useState({
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  })
  const [campaignsSelected, setCampaignsSelected] = useState(dropDownMenus.dashboardCampaigns.map(item => item.label))
  const [areaStudyselected, setAreaStudySelected] = useState(areaStudysMenuOptions.map(item => item.label))

  const selectedCampaigns = dropDownMenus.dashboardCampaigns.filter(option => campaignsSelected.includes(option.label));
  const selectedAreaStudyIds = areaStudysMenuOptions.filter(option => areaStudyselected.includes(option.label));
  const [tableData, setTableData] = useState()
  
const [campaignsDDError, setCampaignDDError]=useState("")
const [areaStudyDDError, setAreaStudyDDError]=useState("")
const fetchReports = async (queryParams) => {
    setLoading(true)
    
    try {
      const [response, groupByDateRes]=await Promise.all([
        getPubDashboardReports(queryParams),
        getPubDashboardOverallTableReports(queryParams)
      ])
  
      setRawReportResponse(response)
      const formatTableData = response.tableContent.map((item) => {
        //  const newItem={...item};
        var momentDate = moment.utc(item['Date/Time']);
        // momentDate.tz('America/Los_Angeles');
        const newRow = {
          "Date": momentDate.format("MM-DD-YYYY"),
          "Commission": `$${item["Total Commission"].toFixed(2)}`,
          "campaign": item["campaignName"],
          "CLICKS": item["CLICKS"],
          
  

        }

        return newRow
      })
      formatTableData.sort(sortByDate)
      
      const updateTable=groupByDateRes.tableContent.map((item)=>{

        var momentDate = moment.utc(item['Date/Time']);
        //  momentDate.tz('America/Los_Angeles');

       const newRow={ "Date": momentDate.format("MM-DD-YYYY"),
        "Commission": `$${item["Total Commission"].toFixed(2)}`,
        "CPC": `$${item["CPC"].toFixed(2)}`,
        "AdRequests":`${item['Ad_Requests']}`,
        "CLICKS": item["CLICKS"],
          "Click-Through-Rate": `${item["CTR"].toFixed(2)}%`,
          "Average Position": item["Avg position"].toFixed(2)
       }

       return newRow
      })
      const overallTableData=modifyDashboardTableData(groupByDateRes.tableContent)
      console.table(overallTableData.sort(sortByDate));
   

      const chartData = createBarChartJson(formatTableData, dropDownMenus.dashboardCampaigns)
    setTableData(overallTableData.sort(sortByDate)) 

      setBarCharts(chartData);
       setLoading(false)
    } catch (err) {
      console.log("err", err)
      setError(err)
      setLoading(true)
    }
  }
  const debounceOnChange = React.useCallback(debounce(fetchReports, 800), []);

  useEffect(() => {

    const queryParams = {
      startDate: moment(dateValue.startDate).format("YYYY-MM-DD"),
      endDate: moment(dateValue.endDate).format("YYYY-MM-DD"),
      mPubID: publisher_id,
      mPubCampaignID: selectedCampaigns.map(camp => camp.value).join(','),
      areaStudy: selectedAreaStudyIds.map(camp => camp.value).join(',')
    }
    const { startDate, endDate } = dateValue;
    
   

    const {mPubCampaignID, areaStudy}=queryParams
   
    if (startDate && endDate &&mPubCampaignID &&  areaStudy) {
      debounceOnChange(queryParams);
    }

  }, [dateValue.startDate, dateValue.endDate, campaignsSelected, areaStudyselected])


  const handelDateChange = useCallback((newValue) => {
    setDateValue(newValue)
  }, [])



  const handleCampaignsDropdowns = (value) => {
    console.log("Value in campaigns", value)
    if (value.length === 0){
      setCampaignsSelected(value)
      setCampaignDDError("Please select any one campaign")
    }else {
      setCampaignsSelected(value)
      setCampaignDDError("")
    }
    
  }

  const handelAreastudyDropdown = (value) => {
    console.log("Value in Area Study", value)
    setAreaStudySelected(value)
    if (value.length === 0){
      setAreaStudyDDError("Please select any one area study")
    }else {
       setAreaStudyDDError("")
    }
  }
  return (

    <Box className='px-0'>


      <Grid container spacing={3}>
        <Grid item xs="12" md="4">
          
          <DateRangePicker dateValue={dateValue} onChangeValue={handelDateChange} />
         
        </Grid>
        <Grid item xs="12" md="4">
          <MultipleSelectCheckBox menuData={dropDownMenus.dashboardCampaigns.map(item => item.label)} menuLabel="Campaigns" menuSelected={campaignsSelected} menuHandler={handleCampaignsDropdowns}   isError={campaignsDDError.length>0}  errorMessage={campaignsDDError}/>
        </Grid>
        <Grid item xs="12" md="4">
          <MultipleSelectCheckBox menuData={areaStudysMenuOptions.map(item => item.label)} menuLabel="Area Study" menuSelected={areaStudyselected} menuHandler={handelAreastudyDropdown} 
          isError={areaStudyDDError.length>0}  errorMessage={areaStudyDDError}
          />
        </Grid>
      </Grid>

       
          <>
          {
            loading? <Box my={5}><Skeleton variant="wave"  height={'50vh'} /></Box> :
            <Box my={5}>
              {
                tableData.length === 0 ? <Paper> <Typography>No data</Typography></Paper> :
                  <Paper className='chart-box'>
                    <Bar data={barChartData} options={overallPageBarOptions} dataSetGroup="revenue"  />
                  </Paper>
              }



            </Box>
          }
            
        {loading? <Box my={5}><Skeleton variant="wave"  height={'50vh'} /></Box> :
            <Box my={5}>
              <Paper className='table-box-reports'>
                <ReportsTable tableContent={tableData} />
              </Paper>
            </Box>}
          </>
        
      



    </Box>

  )
}

export default Dashboard



// console.log("date value", dateValue) 
    // const startDate1 = moment(startDate).format("YYYY-MM-DD");
    // const endDate1 = moment(endDate).format("YYYY-MM-DD");
    // const sdate = dayjs(startDate);
    // const sformattedDate = sdate.format("YYYY-MM-DD");
    // const edate = dayjs(endDate);
    // const eformattedDate = edate.format("YYYY-MM-DD");


    // console.log('formatted S:date',sformattedDate);
    // console.log("formatted e:date", eformattedDate)
    // console.log("end Date moment:::::", endDate)
    // console.log("end Date moment:::::", startDate)