import { API,Auth } from "aws-amplify"
export const getPubDashboardReports=async (queryParams)=>{
    // const { idToken } = await Auth.currentSession();
    const defaultStartDate=`2023-01-01`
    const defaultEndDate=`2023-02-10`
    const myInit = {
        queryStringParameters: {
            mPubID: queryParams.mPubID?queryParams.mPubID:'79557',
            mPubCampaignID:queryParams.mPubCampaignID?queryParams.mPubCampaignID:'',
            startDate:queryParams.startDate?`"${queryParams.startDate}"`:`"${defaultStartDate}"`,
            endDate:queryParams.endDate?`"${queryParams.endDate}"`:`"${defaultEndDate}"`,
            areaStudy:queryParams.areaStudy?queryParams.areaStudy:'',
            
        },
        // headers: {
        //   "X-COG-KEY": idToken.jwtToken
        // }
      };
      console.log("my init", myInit)
      const path = "dashboards";
      const response=await API.get("ReportsDashboard", path, myInit)
      const reportsData = response; 
      console.log("response::::::::::::::::::", response)
     
        return reportsData;

}

// mPubID=50301&mPubCampaignID=14709&areaStudy=1,2,3,4,5,6,7,8,9,10&startDate=%222023-04-01%22&endDate=%222023-04-27%22
export const getPubDashboardOverallTableReports=async (queryParams)=>{
  // const { idToken } = await Auth.currentSession();
  const defaultStartDate=`2023-01-01`
  const defaultEndDate=`2023-02-10`
  const myInit = {
      queryStringParameters: {
          mPubID: queryParams.mPubID?queryParams.mPubID:'79557',
           mPubCampaignID:queryParams.mPubCampaignID?queryParams.mPubCampaignID:'',
          startDate:queryParams.startDate?`"${queryParams.startDate}"`:`"${defaultStartDate}"`,
          endDate:queryParams.endDate?`"${queryParams.endDate}"`:`"${defaultEndDate}"`,
          areaStudy:queryParams.areaStudy?queryParams.areaStudy:'',
          
      },
      // headers: {
      //   "X-COG-KEY": idToken.jwtToken
      // }
    };
    console.log("my init", myInit)
    const path = "campaign/table/overall";
    const response=await API.get("ReportsDashboard", path, myInit)
    const reportsData = response; 
    console.log("response::::::::::::::::::", response)
   
      return reportsData;

}


export const getPubDashboardClicksToLeadTableReports=async (queryParams)=>{
  // const { idToken } = await Auth.currentSession();
  const defaultStartDate=`2023-01-01`
  const defaultEndDate=`2023-02-10`
  const myInit = {
      queryStringParameters: {
        PubID: queryParams.mPubID?queryParams.mPubID:'',
        campaignID:queryParams.mPubCampaignID?queryParams.mPubCampaignID:'',
        mBrandID:queryParams.mBrandId?queryParams.mBrandId:"",
        startDate:queryParams.startDate?`"${queryParams.startDate}"`:``,
        endDate:queryParams.endDate?`"${queryParams.endDate}"`:``,
         
          
      },
      // headers: {
      //   "X-COG-KEY": idToken.jwtToken
      // }
    };
    console.log("my init", myInit)
    const path = "campaign/table/clicktoLead";
    const response=await API.get("ReportsDashboard", path, myInit)
    const reportsData = response; 
    console.log("response::::::::::::::::::", response)
   
      return reportsData;

}



    export const getPublisherRawReports=async (queryParams)=>{
      const { idToken } = await Auth.currentSession();
      const myInit = {
          queryStringParameters: {
              PubID: queryParams.mPubID,
              campaignID:queryParams.mPubCampaignID?queryParams.mPubCampaignID:`13557,48382`,
              startDate:`"${queryParams.startDate}"`,
              endDate:`"${queryParams.endDate}"`,
              areaStudy:queryParams.areaStudy?queryParams.areaStudy:`1,2,3`,
              mBrandID:queryParams.mBrandID?queryParams.mBrandID:`11201`
             

          },
          headers: {
            "X-COG-KEY": idToken.jwtToken
          }
        };
        console.log("my init", myInit)
        const path = "rawreport";
        const response=await API.get("ReportsDashboard", path, myInit)
        const reportsData = response; 
        console.log("response::::::::::::::::::", response)
       
          return reportsData;
  
    }


    export const getPubClickToLeadsReports=async (queryParams)=>{
      // const { idToken } = await Auth.currentSession();
      const defaultStartDate=`2023-01-01`
      const defaultEndDate=`2023-02-10`
      const myInit = {
          queryStringParameters: {
              PubID: queryParams.mPubID?queryParams.mPubID:'',
              campaignID:queryParams.mPubCampaignID?queryParams.mPubCampaignID:'',
              mBrandID:queryParams.mBrandId?queryParams.mBrandId:"",
              startDate:queryParams.startDate?`"${queryParams.startDate}"`:``,
              endDate:queryParams.endDate?`"${queryParams.endDate}"`:``,
              
              
          }
          // headers: {
          //   "X-COG-KEY": idToken.jwtToken
          // }
        };
        console.log("my init", myInit)
        const path = "ClicktoLead";
        const response=await API.get("ReportsDashboard", path, myInit)
        const reportsData = response; 
        console.log("response::::::::::::::::::", response)
       
          return reportsData;
  
  }


  export const generatePublisherRawReports=async (queryParams)=>{
    const { idToken } = await Auth.currentSession();
    const myInit = {
        queryStringParameters: {
            PubID: queryParams.mPubID,
            campaignID:queryParams.mPubCampaignID?queryParams.mPubCampaignID:``,
            startDate:`"${queryParams.startDate}"`,
            endDate:`"${queryParams.endDate}"`,
            areaStudy:queryParams.areaStudy?queryParams.areaStudy:``,
            mBrandID:queryParams.mBrandID?queryParams.mBrandID:``,
            reportType:queryParams.reportType,
            reportFileName:queryParams.reportFileName
           

        },
        headers: {
          "token": idToken.jwtToken
        }
      };
      console.log("my init", myInit)
      const path = "reportsGeneration";

      let response=await API.get("publisher_RAW_report", path, myInit)
//   try{
//  response=await API.get("publisher_RAW_report", path, myInit)

//   }catch(err){
//     console.log(err)
//     response=err
//   }

     
        return response;

  }

  export const generatePublisherReportStatus=async (queryParams)=>{
    const { idToken } = await Auth.currentSession();
    console.log("idToken", idToken)
    const myInit = {
        // queryStringParameters: {
        //     PubID: queryParams.mPubID,
        //     campaignID:queryParams.mPubCampaignID?queryParams.mPubCampaignID:`13557,48382`,
        //     startDate:`"${queryParams.startDate}"`,
        //     endDate:`"${queryParams.endDate}"`,
        //     areaStudy:queryParams.areaStudy?queryParams.areaStudy:`1,2,3`,
        //     mBrandID:queryParams.mBrandID?queryParams.mBrandID:`11201`
           

        // },
        headers: {
          "token": idToken.jwtToken
        }
      };
       console.log("my init", myInit)
      const path = "reportDetails";

      let response =await API.get("publisher_RAW_report", path, myInit)
//   try{
//  response=await API.get("publisher_RAW_report", path, myInit)

//   }catch(err){
//     console.log(err)
//     response=err
  // }

     
        return response;

  }



  export const downloadReport=async (queryParams)=>{
    const { idToken } = await Auth.currentSession();
    console.log("queryParrams", queryParams)
    const myInit = {
       
        body:{
           "file_name":queryParams.file_name?`${queryParams.file_name}.xlsx`:"test.xlsx",
          //  "user_id":queryParams.user_id
        },
        // headers: {
        //   "X-COG-KEY": idToken.jwtToken
        // }
      };
      // console.log("my init", myInit)
      const path = "s3FileDownloadURL";

      let response;
  try{
 response=await API.post("publisher_RAW_report", path, myInit)

  }catch(err){
    console.log(err)
    response=err
  }

     
        return response;

  }

  // https://q5nm2lhhef.execute-api.us-east-1.amazonaws.com/production/v1/s3FileDownloadURL 