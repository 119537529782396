import React from 'react'

const TableHead = () => {

    const columns = [
        // { label: "Status", accessor: "full_name", sortable: true },
        { label: "Campaign ID", accessor: "campaign id", sortable: false },
        { label: "Campaign Name", accessor: "campaign name", sortable: true, sortbyOrder: "desc" },
        { label: "Publisher ID", accessor: "publisher id", sortable: true },
        { label: "Publisher Name", accessor: "publisher name", sortable: true },
      ];


      const renderTableHead=()=>{
      return  <thead className="bg-gray-50">
           <tr>
             {columns.map((col,index)=>{
             return   <th scope="col" className="px-6 py-3 text-xs font-bold  text-gray-500 uppercase ">
               {col.label}
            </th>

             })}
            </tr> 
        </thead>
      }
    return (
        <>
        {renderTableHead()}
        </>
        
    )
}

export default TableHead

