import moment from 'moment/moment';
const backgroundColors = ["#4198D7", "#F5C767", "#7A61BA"]

const barColors = ["#4c78a8", "#bab0ac", "#9d755d", "#ff9da7", "#b279a2", "#eeca3b", "#54a24b", "#72b7b2", "#e45756", "#f58518"]
var colors = [
  "#4C78A8",
  "#F58518",
  "#E45756",
  "#72B7B2",
  "#54A24B",
  "#EECA3B",
  "#B279A2",
  "#FF9DA7",
  "#9D755D",
  "#BAB0AC",
  "#6C5B7B",
  "#E1B16A",
  "#A2D4AB",
  "#EAC8C8",
  "#8E8C6D",
  "#D6BCC0",
  "#C7B8EA",
  "#96C9DB",
  "#D7C9A7",
  "#797979",
  "#D7C9A7",
  "#7DBCC9",
  "#D9D9D9",
  "#BED3F3",
  "#FDB462",
  "#BDD7EE",
  "#C4AD66",
  "#B2B2B2"
];

export const createCampaignBarDatasets = (campaignsArray) => {
  const length = campaignsArray?.length;
  const arrOfBackgroundColors = Array.from({ length }, (_, index) => {
    return colors[index % colors.length];

  });
  const campaignBarDataSets = Array.from(campaignsArray, (element, index) => {
    const obj = {
      label: element.campaignname,
      value: element.SORTID,
      data: [],
      type: "bar",
      backgroundColor: arrOfBackgroundColors[index],
      yAxisID: "A",
      borderColor: "",
      borderWidth: 0,
      order: 2,
      fill: false,
      stack: "all-bars"
    }

    return obj;
  })


  return campaignBarDataSets;
}

export const groupResponse = (ApiRes) => {
  const groupedResponse = ApiRes.reduce((acc, curr) => {
    if (acc[curr.campaign]) {
      acc[curr.campaign].push(curr);
    } else {
      acc[curr.campaign] = [curr];
    }
    return acc;
  }, {});
  return groupedResponse;
}
export const groupResponseByBrandName = (ApiRes) => {
  const groupedResponse = ApiRes.reduce((acc, curr) => {
    if (acc[curr.brandId]) {
      acc[curr.brandId].push(curr);
    } else {
      acc[curr.brandId] = [curr];
    }
    return acc;
  }, {});
  return groupedResponse;
}

export const createBarChartJson = (apiResponse, campaignBarDataSets) => {
  const group_Response = groupResponse(apiResponse)
  // console.log("group response", group_Response)
  const uniqueCampaigns = [...new Set(apiResponse.map((item) => item.campaign))];
  console.log("Unique campaigns", uniqueCampaigns);
  const barDatasets = campaignBarDataSets.filter((item) =>
    uniqueCampaigns.includes(item.label)
  );
  // console.log("bar dataSets", barDatasets);
  const label = apiResponse.map((item) => item.Date).sort((item1, item2) => {
    const newDate1 = new Date(item1);
    const newDate2 = new Date(item2)
    return newDate1 - newDate2;

  })
  const uniqueLabel = Array.from(new Set(label));
  // console.log("uniqueLabel",uniqueLabel)
  const fd = barDatasets.map((item) => {
    const items = group_Response[item.label]
    // console.log("items", items)
    if (items) {
      return {
        ...item,
        // data: group_Response[item.label] && group_Response[item.label].map((content) => content.CLICKS.toString())
        data:group_Response[item.label] && getClicksValue(group_Response[item.label],uniqueLabel )
      }
    }
  })
  const { totalCommission, onlyCommission } = calculateTotalCommission(apiResponse);
  const totalrevenu = {

    label: "Total Commission",
    type: "line",
    data: onlyCommission.map((c) => c.Commission),
    backgroundColor: "#000000",
    yAxisID: "B",
    borderColor: "#000000",
    borderWidth: 2,
    order: "1"

  }


  fd.push(totalrevenu);
  // console.log("final Dataset", fd)

  const chartData = {
    labels: uniqueLabel,
    datasets: fd
  }


  return chartData
}


function calculateTotalCommission(response1) {
  let commissionByDate = {};

  // Loop through each campaign and sum the commission by date
  response1.forEach(campaign => {
    const date = campaign.Date;
    const commission = parseFloat(campaign.Commission.replace(/[^0-9.-]+/g, ""));
    if (commissionByDate[date]) {
      commissionByDate[date] += commission;
    } else {
      commissionByDate[date] = commission;
    }
  });

  // Convert commissionByDate object to an array of objects
  const totalCommission = Object.keys(commissionByDate).map(date => {
    return { Date: date, Commission: `$${commissionByDate[date].toFixed(2)}` };
  });

  const onlyCommission = Object.keys(commissionByDate).map(date => {
    return { Commission: `${commissionByDate[date].toFixed(2)}` };
  });

  return { totalCommission, onlyCommission };
}






export const createBrandsBarDatasets = (brandsArray) => {
  const length = brandsArray.length;
  const arrOfBackgroundColors = Array.from({ length }, (_, index) => {
    return colors[index % colors.length];

  });
  const brandBarDataSets = Array.from(brandsArray, (element, index) => {
    const obj = {
      label: element.label,
      value: element.value,
      data: [],
      type: "bar",
      backgroundColor: arrOfBackgroundColors[index],
      yAxisID: "A",
      borderColor: "",
      borderWidth: 0,
      order: 2,
      fill: false,
      stack: "all-bars"
    }

    return obj;
  })


  return brandBarDataSets;
}



export const createBrandBarChartJson = (apiResponse, campaignBarDataSets) => {

  // const {label, newResponse}=generateLabelAndSortedArray(apiResponse)
  const group_Response = groupResponse(apiResponse)
  console.log("GROUped response", group_Response)
  const uniqueBrands = [...new Set(apiResponse.map((item) => item.campaign))];
  // console.log("Unique campaigns", uniqueBrands);
  const barDatasets = campaignBarDataSets.filter((item) =>
    uniqueBrands.includes(item.label)
  );
  // console.log("bar dataSets", barDatasets);

const sumCommision=(data)=>{
  const commissionTotal = data.reduce((accumulator, currentValue) => {
    const commission = parseFloat(currentValue.Commission.replace('$', ''));
    return accumulator + commission;
  }, 0);
  
  const commissionTotalString =[ commissionTotal.toFixed(2).toString()];
  // console.log("commissionTotalString" + commissionTotalString);
  return commissionTotalString
  // console.log("$" + commissionTotalString); // Output: $278.25
  
}

  const fd = barDatasets.map((item) => {
    const items = group_Response[item.label]
    // console.log("items", items)
    if (items) {
      return {
        ...item,
        data: group_Response[item.label] && group_Response[item.label].map((content) => content.Commission.replace(/[^0-9.-]+/g, "").toString())
      }
    }
  })

  // group_Response[item.label].map((content) => content.Commission.replace(/[^0-9.-]+/g, "").toString())

  // const {totalCommission, onlyCommission} = calculateTotalCommission(apiResponse);    
  //     const totalrevenu={

  //         label: "Total Commission",
  //         type: "line",
  //         data: onlyCommission.map((c)=>c.Commission),
  //         backgroundColor: "#000000",
  //         yAxisID: "B",
  //         borderColor: "#000000",
  //         borderWidth: 2,
  //         order: "1"

  //  }

  // const commissionTotal = data.reduce((accumulator, currentValue) => {
  //   const commission = parseFloat(currentValue.Commission.replace('$', ''));
  //   return accumulator + commission;
  // }, 0);
  //  fd.push(totalrevenu);
  // console.log("final Dataset", fd)
  const label = apiResponse.map((item) => item.Date).sort((item1, item2) => {
    const newDate1 = new Date(item1);
    const newDate2 = new Date(item2)
    return newDate1 - newDate2;

  })
  // console.log("label", label)
  const uniqueLabel = Array.from(new Set(label));
  const chartData = {
    labels: uniqueLabel,
    datasets: fd
  }
console.log("Chart data", chartData)

  return chartData
}


export const createBarChartJsonForBrand = (apiResponse, campaignBarDataSets) => {

  // const {label, newResponse}=generateLabelAndSortedArray(apiResponse)
  const group_Response =groupResponseByBrandName(apiResponse)
  console.log("GROUped response", group_Response)
  const uniqueBrands = [...new Set(apiResponse.map((item) => item.brandId))];
  // console.log("Unique campaigns", uniqueBrands);
  const barDatasets = campaignBarDataSets.filter((item) =>
    uniqueBrands.includes(Number(item.value))
  );
const label = apiResponse.map((item) => item.Date).sort((item1, item2) => {
  const newDate1 = new Date(item1);
  const newDate2 = new Date(item2)
  return newDate1 - newDate2;

})
// console.log("label", label)
const uniqueLabel = Array.from(new Set(label));
  const fd = barDatasets.map((item) => {
    const items = group_Response[item.value]
    // console.log("items", items)
    if (items) {
      return {
        ...item,
        data: group_Response[item.value] && getClicksValue(group_Response[item.value], uniqueLabel)
      }
    }
  })
  const { totalCommission, onlyCommission } = calculateTotalCommission(apiResponse);
  const totalrevenu = {

    label: "Total Commission",
    type: "line",
    data: onlyCommission.map((c) => c.Commission),
    backgroundColor: "#000000",
    yAxisID: "B",
    borderColor: "#000000",
    borderWidth: 2,
    order: "1"

  }


  fd.push(totalrevenu);
  console.log("final Dataset", fd)


  // group_Response[item.label].map((content) => content.Commission.replace(/[^0-9.-]+/g, "").toString())

  // const {totalCommission, onlyCommission} = calculateTotalCommission(apiResponse);    
  //     const totalrevenu={

  //         label: "Total Commission",
  //         type: "line",
  //         data: onlyCommission.map((c)=>c.Commission),
  //         backgroundColor: "#000000",
  //         yAxisID: "B",
  //         borderColor: "#000000",
  //         borderWidth: 2,
  //         order: "1"

  //  }

  // const commissionTotal = data.reduce((accumulator, currentValue) => {
  //   const commission = parseFloat(currentValue.Commission.replace('$', ''));
  //   return accumulator + commission;
  // }, 0);
  //  fd.push(totalrevenu);
  // console.log("final Dataset", fd)

  const chartData = {
    labels: uniqueLabel,
    datasets: fd
  }
console.log("Chart data", chartData)

  return chartData
}




const getClicksValue=(data,label)=>{
  
  const clicksData = label.map(labelDate => {
      const dataObj = data.find(obj => obj.Date === labelDate);
      return dataObj ? dataObj.CLICKS : 0;
    });
return clicksData;      
}







export const generateLabelAndSortedArray = (apiResponse) => {

  const label = [];
  const newResponse = apiResponse.map((item) => {
    const newDate = moment(item['Date/Time']).format("MM-DD-YYYY")
    label.push(newDate)
    return {
      ...item,
      "Date": newDate
    }
  }).sort((item1, item2) => {
    const newDate1 = new Date(item1.Date);
    const newDate2 = new Date(item2.Date)
    return newDate1 - newDate2;

  })
  return {
    label,
    newResponse
  }
}

export const overallPageBarOptions = {
  responsive: "true",
 maintainAspectRatio:false,
  scales: {

    A: {
      id: "A",
      type: "linear",
      position: "left",
      title: {
        display: true,
        text: "Clicks"
      },

      ticks: {

        callback: function (value, index, ticks) {
          //  return data1.datasets[0].data[index];
          //  console.log("value", value, ticks);
          // const addValue=
          if (value <= 2500 || value >= 0) {
            return value;
          }
        },
        stepSize: 0
      },
      //  stacked:true,

    },
    B: {
      id: "B",
      type: "linear",
      position: "right",
      title: {
        display: true,
        text: "Commission"
      },
      grid: { display: false },
      ticks: {

        callback: function (value, index, ticks) {
          return '$' + value;

        },
        stepSize: 0
      },
      //  stacked:true,

    },

    x: {
      stacked: true,
      grid: { display: false },
      title: {
        display: true,
        text: "Date/ Time"
      },
    }

  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          console.log("chart contexxt", context)
          if (context.dataset.type === 'line') {
            var label = context.dataset.label;
            var value = context.parsed.y;
            return label + ':  $ ' + value;
            // return '$' + context.parsed.y
          }
          // return '$' + context.parsed.y / 1000000000 + 'B';
        }
      }
    }
  }

}

export const clicksToLeadOptions = {
  responsive: "true",
   maintainAspectRatio:false,
  scales: {

    A: {
      id: "A",
      type: "linear",
      position: "left",
      title: {
        display: true,
        text: "clicks"
      },

      ticks: {

        callback: function (value, index, ticks) {
 if (value <= 2500 || value >= 0) {
            return value;
          }
        },
        stepSize: 0
      },
      //  stacked:true,

    },
    B:{
      id: "B",
      type: "linear",
      position: "right",
      title:{
       display:true,
       text:"Commission"
      },
      grid:{
        display:false
      },

      ticks: {

        callback: function(value, index, ticks) {
          return  "$" + value;

        },
         stepSize: 0,
         showLabelBackdrop:false
      },
      //  stacked:true,

    },

    x: {
      stacked: true,
      grid: { display: false },
      title: {
        display: true,
        text: "Date/ Time"
      },
    }

  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          // console.log("chart contexxt", context)
          if (context.dataset.type === 'line') {
            // console.log("chart contexxt", context)
            
            var label = context.dataset.label;
            var value = context.parsed.y;
            // console.log("chart contexxt label", label)
            // console.log("chart contexxt value", value)
            // console.log("chart contexxt return ", label + ':  $' + value)
            return label + ':  $' + value;
            // return '$' + context.parsed.y
          }
          // return '$' + context.parsed.y / 1000000000 + 'B';
        }
      }
    }
  }
}
