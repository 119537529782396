import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getCampaign } from '../../../Store/Slice_reducer/publisherCampaignSlice';
import ResponsiveDrawer from '../../Components/New Home/MMHome';
import CampaignTable from '../../Components/Table/ReportsTable'
import Table from '../../../Components/Layout/Table/TableLayout';
import CircularLoader from '../../../Components/Layout/Loader';
import { Box, Paper } from '@mui/material';
import { getLast5DigitNumberPublisherGroup } from '../../../Utils/utils';
import { useOutletContext } from 'react-router-dom';
const PubCampaigns = () => {
  const dispatch=useDispatch();
 const pubCampData = useSelector((state) => state.pubCampData);
console.log("pubCampData", pubCampData)
 const {loading, campaigns}=pubCampData
const [userJwt, group] = useOutletContext();

  useEffect(() => {
    // console.log()
  // const publisherID = getLast5DigitNumberPublisherGroup(group[0])
  // const publisherID ='50301'
    // const param={
    //   publisherID:publisherID
    //  }
  //  dispatch(getCampaign(param))
 
  }, [])


  // console.log("campaugns", campaigns);

  return (
    <>

    
      <ResponsiveDrawer>
      {/* {loading ? <Box my={1} display="flex" justifyContent="center"><CircularLoader /></Box> : */}
      <Box my={1}>
        <Paper>
       <Table campaignData={campaigns} />
       </Paper>
       </Box>
      
       </ResponsiveDrawer>
    
  </>

  )
}

export default PubCampaigns