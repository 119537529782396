import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API ,Auth} from "aws-amplify";
import { createCampaignsDropdown } from "../../Utils/utils";
import { createCampaignBarDatasets } from "../../Utils/chartsDs";

export const getCampaign = createAsyncThunk("publisher/getCampaign", async (publisherInfo, { getState }) => {
const state = getState();
const userData=state.userData
console.log(userData, "at");
console.log(publisherInfo, "pub info");
let response=await getCampaignData(publisherInfo.publisherID, publisherInfo.token)
return response;
});

const publisherCampaignSlice = createSlice({
  name: "campaignsData",
  initialState: { 
    campaigns: [
      
    ],
    loading: true,
    check:true
  },
  reducers:{
          handelTest: (state, action) => {
        return {...state, check:action.payload}
    }
  },
  extraReducers: {
    [getCampaign.fulfilled]: (state, action) => {
      //If we have to totally replace the existing array:
      // state.todos = action.payload;

      //if we want to add the json to an existing array
      let updateCampaigns =action.payload;
      let campaignsDropdown=createCampaignBarDatasets(updateCampaigns)
      state.campaigns = updateCampaigns;
      state.campaignsDropdown=campaignsDropdown
      state.loading = false;
    },
    [getCampaign.pending]: (state) => {
      state.loading = true;
    },
    [getCampaign.rejected]: (state) => {
      state.loading = true;
    }
  }
});

export default publisherCampaignSlice.reducer;
// this is for dispatch
export const { handelTest } = publisherCampaignSlice.actions;


const getCampaignData= async (publisherID, token)=> {
      const { idToken } = await Auth.currentSession();
      const path = "campaigns";
      const myInit = {
        queryStringParameters: {
          publisherID: publisherID,
        },
        headers: {
          "X-COG-KEY": idToken.jwtToken
        }
      };

   
     const response=await API.get("ReportsDashboard", path, myInit)
     const campaignData = response; 
    
    
       return campaignData
  
    }