import React, { useState } from 'react'
import './Login.css'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { Link, useNavigate } from 'react-router-dom';
import { loginUserService } from '../../Service/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrorMessage, handelChangePasswordModalOpen, loginUser, storeUserInfo } from '../../Store/Slice_reducer/UserSlice';
import { useEffect } from 'react';
import CreatePassword from './CreatePassword';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#E1E4E9',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export const Login = () => {
  let navigate = useNavigate();
  const dispatch=useDispatch()
  const userStore=useSelector((state)=>state.userData)
  // console.log(userStore,'uuss')
  
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [userData, setUserData] = useState({
    email: "",
    password: ""
  })
  
  const [errorModal, setErrorModal]=useState(false)
  const [error, setError] = useState({
    email: '',
    password: ''
  })
  const handleInput = (e) => {

    const { name, value } = e.target
    if (name === 'password') {
      if (!passwordRegex.test(value)) {
        console.log("track:::::::::::::::")
        setError((prev) => ({
          ...prev, [name]: "Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
        }))
      } else {
        setError((prev) => ({
          ...prev, [name]: ""
        }))

      }

    } else {
      if (!value) {
        setError((prev) => ({
          ...prev, [name]: "Please enter the user name"
        }))
      } else {
        setError((prev) => ({
          ...prev, [name]: ""
        }))
      }
    }
    //   console.log("e",e)
    setUserData((prev) => ({
      ...prev, [name]: value
    }))
  }
  const handleLoginButton = async(e) => {
    e.preventDefault();
    // console.log("userDarta", userData)
    dispatch(clearErrorMessage())
    const isValid = validateForm();
    // console.log("userDarta", isValid)
//  const userRes=await loginUserService(userData.email, userData.password)
  //  console.log("userData", userStore)


   dispatch(loginUser({
    email:userData.email,
    password:userData.password
   }))
   console.log("userData", userStore)

   



//  console.log("USER res", userRes)
  //   Auth.signIn(userData.email, userData.password).then((res) => {
  //     console.log("res", res)
  //     dispatch(storeUserInfo(res))
  //     navigate("/report")

  //   }).catch((err) => {
  //     console.log(err, "err")
  //   })

   }


  const validateForm = () => {
    let valid = false;
    if (error.email.length > 0) {
      valid = false
    }
    if (error.email.length > 0) {
      valid = false
    }
    return valid;

  }



  useEffect(()=>{
    const isEmptyUserObject=Object.keys(userStore.user).length === 0 && userStore.user.constructor === Object
    const isEmptyErrorObject=Object.keys(userStore.error).length === 0 && userStore.error.constructor === Object
    console.log("isUserObjEmpty", isEmptyUserObject)
    console.log("isErrorObjEmpty", isEmptyErrorObject)


    console.log("userStore.user", userStore.user)
    console.log("userStore.error", userStore.error)
    if(!isEmptyUserObject){
      console.log(" track 1")
      if(userStore.user.challengeName==="NEW_PASSWORD_REQUIRED"){
      //  write API LOGIC first set the user pwd the update the user pool
      dispatch(handelChangePasswordModalOpen(true))
      }else{
        navigate("/report")
       }
      
     }
  
     if(!isEmptyErrorObject){
      setErrorModal(true)
     }
  }, [userStore.error, userStore.user])
  console.log("errorModal", userStore.error)
  return (
    <>
    <div className="min-h-screen flex flex-col items-center justify-center bg-login-bg">
      <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
         <div className='logo_container'>
        <img src='/images/Media-Matchers-logo.svg' alt='logo'/>
         </div>
        <div className="mt-10">
          <form>
          
          <TextField
          error={error.email.length>0}
          id="filled-error-helper-text"
          label="User name"
          defaultValue=""
          helperText={error.email}
          variant="outlined"
          name="email"
          onChange={handleInput}
          className='password-text-input'
          sx={{marginBottom:"20px", width:'100%'}}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <PersonIcon />
            </InputAdornment>,
          }}
        />
        
          <FormControl sx={{width:'100%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            className='password-text-input'
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onChange={handleInput}
            error={error.password.length>0}
            label="Password"
            name='password'
            
            
            
          />
           {error.password.length>0 && <span className="error-message">{error.password}</span>}
        </FormControl>

            <div className="flex items-center mb-6 mt-4">
              <div className="flex ml-auto">
                <p className="inline-flex text-xs sm:text-sm mr-3">Forgot Your Password?</p>
                <br></br>
                <Link to='/resetPassword' className="inline-flex text-xs sm:text-sm text-mm-blue hover:text-mm-blue">Reset Password</Link>
              </div>
            </div>

            <div className="flex w-full">
              <button  className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-mm-blue hover:bg-mm-blue rounded py-2 w-full transition duration-150 ease-in" onClick={(e) => handleLoginButton(e)}>
               <>
               {userStore.loading?<>Loading....</>:
               <>
               <span className="mr-2 uppercase">Login</span>
                <span>
                  <svg className="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
               </>
}
               </>
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>

   
    <Modal
         open={errorModal}
       
        onClose={()=>setErrorModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" color={"#00AAE3"}>
         Error
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           {userStore.error.message}
          </Typography>
        </Box>
      </Modal>
     <CreatePassword />
    </>
  )
}
