import React, { useState } from 'react'
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import FormControl from '@mui/material/FormControl';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormHelperText, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordNewUser, handelChangePasswordModalOpen, handelConfirmPassword, handelNewPassword, handelPasswordError } from '../../Store/Slice_reducer/UserSlice';
import { createAndConformPwdForNewUser } from '../../Service/Auth';
import { Auth, API } from 'aws-amplify';
const style1={
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#E1E4E9',
    border: '3px solid #00AAE3',
    boxShadow: 24,
    p: 4,
}

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
const CreatePassword = () => {
    const dispatch=useDispatch()
    const userStore=useSelector((state)=>state.userData)
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
    const [loading , setLoading]=useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowPasswordConfirm((show) => !show);


   const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
   const passwordError=userStore.createPasswordError
  const handelChangePassword=async ()=>{
    console.log('tt')
    // setLoading(true)
    if (userStore.newPassword !== userStore.confirmPassword) {
        // Display an error message or highlight the fields as invalid
        dispatch(handelPasswordError("Password not matched"))
        return;
      }else if(!userStore.newPassword || !userStore.confirmPassword ){
        dispatch(handelPasswordError("Please enter new password"))
      }else{
        const {user, newPassword}=userStore
      const userInfo={
        user:user,
        newPassword,
        userName:user.username
      }
      // console.log("user info", userInfo)
          // dispatch(changePasswordNewUser(userInfo))

      //  Here  I am gonna write code to make 2 api call first call with normal service if success dispatch update user action and error action.
      setLoading(true)
      const response=createAndConformPwdForNewUser(userInfo.user, userInfo.newPassword, userInfo.userName);
      response.then((res)=>{
        console.log("res::::confirm password", res)
        updateUser();
      }).catch((err)=>{
        //  dispatch()
        console.log('err', err);
      })


       
      }

  }

const handelnewPasswordInput=(e)=>{
    const {name, value}=e.target
    if(name==='newPassword'){
        if (!passwordRegex.test(value)) {
            dispatch(handelPasswordError("Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"))
        }else{
            dispatch(handelPasswordError(""))
        }
        dispatch(handelNewPassword(value))

    }else if(name==='confirmPassword'){
        dispatch(handelConfirmPassword(value))
    }

}


const updateUser=async()=> {
  const {
    idToken: { jwtToken },
  } = await Auth.currentSession();
  const myInit = {
    headers: {
      "X-COG-KEY": jwtToken,
    },
    body: {
      Username: userStore.user,
      UserAttributes: [
        {
          Name: "email_verified",
          Value: "true",
        },
      ],
    },
  };
  API.put("MerchantData", "user", myInit).then((response) => {
    // console.log(response);
    localStorage.clear()
    setLoading(false)
    dispatch(handelChangePasswordModalOpen(false))
  });
}

  return (
    <Modal
          open={userStore.openChangePasswordModal}
    //    open={true}
        onClose={()=>dispatch(handelChangePasswordModalOpen(false))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
        <FormControl sx={{ m: 1, width:"100%" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            className='password-text-input'
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Change Password"
            // onChange={(event)=>{dispatch(handelNewPassword(event.target.value))}}
            onChange={handelnewPasswordInput}
            error={passwordError.length>0}
            name='newPassword'
          />
        </FormControl>
        <FormControl sx={{ m: 1, width:"100%"}} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPasswordConfirm? 'text' : 'password'}
            className='password-text-input'
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPasswordConfirm? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm Password"
            // onChange={(event)=>{dispatch(handelConfirmPassword(event.target.value))}}
            error={passwordError.length>0}
            name='confirmPassword'
            onChange={handelnewPasswordInput}
          />
          {passwordError.length>0 && (
    <FormHelperText error id="accountId-error">
      {passwordError}
    </FormHelperText>
  )}
        </FormControl>
        <Box className='btn_container' >
          <LoadingButton onClick={handelChangePassword} loadingPosition="center" loading={loading}>Change password</LoadingButton>
        </Box>
        </Box>
      </Modal>
  )
}

export default CreatePassword