import React, { useState, useEffect } from "react";
import TableBody from "./TableBody";
import TableHead from "./TableHead";

import './Table.css'
// import CampaignsMock from '../../../Mock/publisherCampaigns.json'
import { useMemo } from "react";
// import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { Box, Skeleton } from "@mui/material";
export default function Table({ campaignData }) {


    const pubCampData = useSelector((state) => state.pubCampData);
    console.log("pubCampData", pubCampData)
     const {loading, campaigns}=pubCampData
     console.log("pubCampData loading ", loading)
     console.log("pubCampData campaigns ", campaigns)
   




    const itemsPerPage = 10
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const [search, setSearch] = useState('')
    const [showPagination, setShowPagination] = useState(campaignData.length > 10 ? true : false)

    const [list, setList] = useState({
        itemsDisplayed: 10,
        data: campaignData.slice(0,10),
      });


    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % campaignData.length;
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
    };
    const handleInputSearch = (e) => {
        setSearch(e.target.value)
        setItemOffset(0);

    }
      let dataTable = React.createRef();
  

    const filterCampaignsData = useMemo(
        () => {
            let computedcampaign = list.data;
            const endOffset = itemOffset + itemsPerPage;
            if (search) {
                computedcampaign = computedcampaign.filter(
                    campaign =>
                        campaign.campaignname.toLowerCase().includes(search.toLowerCase()) ||
                        campaign.SORTID.toLowerCase().includes(search.toLowerCase())
                );
                setList({
                    itemsDisplayed:list.itemsDisplayed,
                    data:computedcampaign?.slice(itemOffset, endOffset)
                })
            } else {
                // setCurrentItems(list.data?.slice(itemOffset, endOffset))
                setList({
                    itemsDisplayed:list.itemsDisplayed,
                    data:campaignData.slice(0,10),
                })
            }

        },
        [search],
    );
  
    let onScroll = () => {
        console.log("track", campaignData.length)
        console.log("list", list)
        let tableEl = dataTable.current;
        if (tableEl.scrollTop === (tableEl.scrollHeight - tableEl.offsetHeight)) {
          if (list.itemsDisplayed + 5 <= campaignData.length) {
            setList({
              itemsDisplayed: list.itemsDisplayed + 10,
              data: campaignData.slice(0, list.itemsDisplayed + 10),
            });
          }
        }
      };

      useEffect(()=>{
        window.addEventListener('scroll', onScroll)
        return ()=>{
            window.removeEventListener('scroll', onScroll)
        }
      })
    return (
       
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className="flex justify-between py-3 pl-2">
                    
                    <div className="relative max-w-xs">
                        <label htmlFor="hs-table-search" className="sr-only">
                            Search
                        </label>
                        <input
                            type="text"
                            name="hs-table-search"
                            id="hs-table-search"
                            className="block w-full p-3 pl-10 text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
                            placeholder="Search..."
                            value={search}
                            onChange={handleInputSearch}
                        />
                        <SearchIcon />
                    </div>
              

                </div>

                <div className="p-1.5 w-full inline-block align-middle">
                {loading ?<Box my={5}><Skeleton variant="wave"  height={'50vh'} /></Box>:
                    <div className="overflow-hidden border rounded-lg mt-2">
                        <table className="min-w-full divide-y divide-gray-200" ref={dataTable} onScroll={onScroll}>

                            <TableHead />

                            <TableBody campaignData={list.data ?list.data : []} />
                        </table>

                    </div>
                }
                </div>
            </div>
           


        </div>
    
    );
}

const SearchIcon = () => {
    return (
        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
            <svg
                className="h-3.5 w-3.5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
            >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
        </div>
    )
}




