import React from "react";
import Datepicker from "react-tailwindcss-datepicker";

const DateRangePicker = ({dateValue,onChangeValue, maxDate, minDate}) => {
  
    
    // const dashboardDateData = useSelector((state) => state.pubReportsData);
    // const handleValueChange = (newValue) => {
    //     console.log("NEW date value",newValue)
    //     dispatch(handelDateRangePicker(newValue))
    // }
    
    return (
        <div className="date-range-container">
          <label>Select Dates</label>
            <Datepicker
                value={dateValue}
                popoverDirection="down" 
                displayFormat={"MM/DD/YYYY"} 
                onChange={onChangeValue}
                showShortcuts={true} 
                primaryColor={"#00AAE3"} 
                maxDate={maxDate}
                minDate={minDate}
                separator={"-"}
                 configs={{
                    shortcuts: {
                    today:"Intraday", 
                    yesterday:"Previous Day",
                    currentMonth:"Current Month", 
                    pastMonth:"Previous Month",
                //    past: period => `Previous ${period} Days`,
                   last7Days: { 
                    text: "Previous 7 days",
                    period: {
                    start: new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000),
                    end: new Date()
                    }, 
                    },
                    last30Days: { 
                        text: "Previous 30 days",
                        period: {
                        start: new Date(new Date().getTime() - 29 * 24 * 60 * 60 * 1000),
                        end: new Date()
                        }, 
                        },
                
                    },
                   
                    }} 

                
              
            />
        </div>
    );
};

export default DateRangePicker;


const createShortCuts=()=>{
    const currentDate = new Date();
    const startOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
    const previousDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1, 0, 0, 0);
    const endOfPreviousDay = new Date(previousDay.getFullYear(), previousDay.getMonth(), previousDay.getDate(), 23, 59, 59);
    const sevenDaysAgo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7, 0, 0, 0);
    const thirtyDaysAgo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30, 0, 0, 0);
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
   
    const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1, 0, 0, 0);
    const endOfPreviousMonth = new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 0, 23, 59, 59);

    const shortcuts={
        intraday:{
            text: "Intraday",
            period: {
            start:startOfDay,
                end:  currentDate
        },
        },
        previousDay:{
            text: "Previous Day",
            period: {
            start:previousDay,
                end:  endOfPreviousDay
        }, 

        },
        previous7Days:{
            text: "Previous 7 days",
            period: {
            start:sevenDaysAgo,
                end:  currentDate
        } 
        
        },
        previous30Days:{
            text: "Previous 30 days",
            period: {
            start:thirtyDaysAgo,
                end:  currentDate
        } 
            
        },
        currentMonth:{
            text: "Current Month",
            period: {
            start:startOfMonth,
                end:  currentDate
        } 
            
        }, 
        previousMonth:{
            text: "Previous Month",
            period: {
            start: previousMonth,
                end: endOfPreviousMonth
        } 
            
        }, 


    }

return shortcuts;

}