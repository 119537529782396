import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { API ,Auth} from "aws-amplify";
import { loginUserService, updateUser, createAndConformPwdForNewUser } from "../../Service/Auth";
import { useDispatch } from 'react-redux';
export const loginUser = createAsyncThunk("MM/loginUser", async (userInfo) => {
  let response = await loginUserService(userInfo.email, userInfo.password)

  return response

});


export const changePasswordNewUser = createAsyncThunk("MM/loginUserChangePassword", async (userInfo) => {
  let response = await createAndConformPwdForNewUser(userInfo.user, userInfo.newPassword, userInfo.userName)
  return response;
});

export const update_User = createAsyncThunk("MM/updateUser", async (userInfo, { getState, dispatch }) => {
  // let response=await createAndConformPwdForNewUser(userInfo.user, userInfo.newPassword, userInfo.userName)
  // return response;

  console.log("track 1")
  const { user, changePasswordNewUserStatus } = getState.userSlice()
  let res = '';
  if (changePasswordNewUserStatus) {
    res = await updateUser(user.username)

  }
  return res;
});



const userSlice = createSlice({
  name: "Auth",
  initialState: {
    user: {

    },
    loading: false,
    check: true,
    error: {},
    openChangePasswordModal: false,
    newPassword: "",
    confirmPassword: "",
    createPasswordError: "",
    changePasswordNewUserStatus: "",
    changePasswordLoading: false

  },
  reducers: {
    handelLogout: (state, action) => {
      localStorage.removeItem('userId')
      return { ...state, user: {}, error: {}, loading: false }
    },
    storeUserInfo: (state, action) => {
      const userId = action.payload
      console.log("UserId", userId)
      return { ...state, user: action.payload }
    },
    clearErrorMessage: (state, action) => {
      return {
        ...state, error: {}, user: {}, loading: false
      }
    },
    handelChangePasswordModalOpen: (state, action) => {
      return { ...state, openChangePasswordModal: action.payload }
    }
    , handelNewPassword: (state, action) => {
      return {
        ...state, newPassword: action.payload
      }
    },
    handelConfirmPassword: (state, action) => {
      return {
        ...state, confirmPassword: action.payload
      }
    },
    handelPasswordError: (state, action) => {
      return {
        ...state, createPasswordError: action.payload
      }
    },


  },

  extraReducers: {
    [loginUser.fulfilled]: (state, action) => {
      //If we have to totally replace the existing array:
      // state.todos = action.payload;

      //if we want to add the json to an existing array
      let updateUser = { ...state.user };
      updateUser = action.payload
      localStorage.setItem("userId", JSON.stringify(action.payload))
      state.user = updateUser;
      state.loading = false;
    },
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.rejected]: (state, action) => {
      console.log("error in redux thunk", action)
      state.error = action.error
      state.loading = false;

    },
    [changePasswordNewUser.fulfilled]: (state, action) => {
      // updateUser(action.payload.username)
      // state.openChangePasswordModal=false
      state.changePasswordNewUserStatus = "success"
      const dispatch = useDispatch();
      dispatch(update_User())

      console.log("action.payload changePasswordNewUser.fulfilled", action.payload)
    },
    [changePasswordNewUser.pending]: (state) => {
      state.changePasswordLoading = true;
      state.changePasswordNewUserStatus = ""
    },
    [changePasswordNewUser.rejected]: (state, action) => {
      console.log("error in redux thunk", action)
      state.error = action.error
      state.changePasswordNewUserStatus = ""
      state.openChangePasswordModal = false
      state.changePasswordLoading = false;

    },
    [update_User.fulfilled]: (state, action) => {
      // updateUser(action.payload.username)
      state.changePasswordLoading = false;
      state.openChangePasswordModal = false
      // state.update_User="success"
      state.changePasswordNewUserStatus = ""
      localStorage.clear();
      console.log("action.payload update_User.fulfilled", action.payload)
    },
    [update_User.pending]: (state) => {
      state.changePasswordLoading = true;
      state.openChangePasswordModal = true
      // state.changePasswordNewUserStatus=""
      // state.update_UserStatus=""
    },
    [update_User.rejected]: (state, action) => {
      console.log("error in redux thunk", action)
      state.error = action.error
      // state.update_UserStatus=""
      state.openChangePasswordModal = false
      state.changePasswordLoading = false;

    }
  }
});

export default userSlice.reducer;
// this is for dispatch
export const { handelLogout, storeUserInfo, clearErrorMessage, handelChangePasswordModalOpen, handelNewPassword, handelConfirmPassword, handelPasswordError } = userSlice.actions;
