import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Skeleton } from '@mui/material';
import DownloadFileComponent from '../DownloadButton/DownloadFileComponent';
import moment from 'moment/moment';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00AAE3",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(rName, rType,status,lastExecution, pdfIcon) {
  return { rName, rType,status,lastExecution, pdfIcon };
}

const rows = [
  createData('Consumer Attribute Performance report', "Performance report", "pending", "2023-10-30*02:47:33:899", <PictureAsPdfRoundedIcon />),
  createData('More raw report', "Performance report", "pending", "2023-10-30*02:47:33:899", <PictureAsPdfRoundedIcon />),
  createData('More raw report', "Performance report", "pending", "2023-10-30*02:47:33:899", <PictureAsPdfRoundedIcon />),
//   createData('Last Execution', 305, 3.7, 67, 4.3),
//   createData('Download', 356, 16.0, 49, 3.9),
];

export default function SimpleTable({tableContent}) {
console.log("Table content ", tableContent)

const rows1=tableContent.map((item)=>{
     return  createData(item.file_name, item.job_type, item.status, item.last_execution, <DownloadFileComponent itemStatus={item.status}  fileDownloadUrl={item.reportFileUrl}  fileName={item.s3_file_name} />)
});


console.log("rows1", rows1)
  // const handelReportDownload=()=>{
  //   console.log("download ,,,....")
  // }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Report Name</StyledTableCell>
            <StyledTableCell align="center">Report Type</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="center">Last Execution</StyledTableCell>
            <StyledTableCell align="center">Download</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows1.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.rName?.replace(/\.[^.]+$/, "")}
              </StyledTableCell>
              <StyledTableCell align="center">{row.rType?.replace(/"/g, "")}</StyledTableCell>
              <StyledTableCell align="center">{row?.status}</StyledTableCell>
              <StyledTableCell align="center">{ moment.utc(row?.lastExecution)?.format("MM-DD-YYYY")}</StyledTableCell>
              <StyledTableCell align="center">{row?.pdfIcon}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const LoadingSkeleton = () => (
  <Box
    sx={{
      height: "max-content"
    }}
  >
    {[...Array(10)].map((_) => (
      <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
    ))}
  </Box>
);