export const staging_1 = {
    Auth: {
      userPoolId: "us-east-1_2vsXozcgn",
      region: "us-east-1",
      userPoolWebClientId: "ba7l3ma1n7veu68cgh5ol1pce",
    
    },
    API: {
      endpoints: [
        {
          name: "MerchantData",
          endpoint:"https://ijk4ndlys1.execute-api.us-east-1.amazonaws.com/search/",
        }, 
        {
          name: "CapSettings",
          endpoint:"https://qrwgl1ucz8.execute-api.us-east-1.amazonaws.com/search/",
        },
        {
          name: "reports",
          endpoint:"https://glfmk6pn7f.execute-api.us-east-1.amazonaws.com/search/",
        },
        {
          name: "dashboard",
          endpoint:"https://k4vfkoyv91.execute-api.us-east-1.amazonaws.com/staging/",
        },
        {
          name: "updateCampaignStatus",
          endpoint:"https://x7jl6uqo43.execute-api.us-east-1.amazonaws.com/search/",
        },
        {
          name: "ReportsDashboard",
          endpoint:"https://bc4r9h11e4.execute-api.us-east-1.amazonaws.com/staging/v1/",
        },
        {
          name:"publisher_RAW_report",
          endpoint:"https://q5nm2lhhef.execute-api.us-east-1.amazonaws.com/production/v1/"
         }
        
      ],
    }
  }

  export const production_1 = {

    Auth: {
      userPoolId: "us-east-1_vRkhJ94So",
      region: "us-east-1",
      userPoolWebClientId: "6ed8lu58vjs3pj9svkvpo5csmj",
    },
    API: {
      endpoints: [
        {
          name: "MerchantData",
          // endpoint:"https://ijk4ndlys1.execute-api.us-east-1.amazonaws.com/search/",
          endpoint: "https://81in6x1y2e.execute-api.us-east-1.amazonaws.com/search/",
        },
        {
          name: "reports",
          endpoint: "https://ijk4ndlys1.execute-api.us-east-1.amazonaws.com/search",
  
        },
        {
          name: "CapSettings",
          endpoint: "https://1dcs12vb92.execute-api.us-east-1.amazonaws.com/search/",
        },
        {
          name: "dashboard",
          endpoint: "https://skzd8dg8m7.execute-api.us-east-1.amazonaws.com/production/",
        },
        // {
        //   name: "ReportsDashboard",
        //   endpoint:"https://bc4r9h11e4.execute-api.us-east-1.amazonaws.com/staging/v1/",
        // },
        {
          name: "ReportsDashboard",
          endpoint:"https://5zr7v6ljsh.execute-api.us-east-1.amazonaws.com/production/v1/",
        },
         {
          name:"publisher_RAW_report",
          endpoint:"https://q5nm2lhhef.execute-api.us-east-1.amazonaws.com/production/v1/"
         }
      ],
    }
  }
  