import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import config  from './build-env-config/env-config';
import { Amplify, Auth } from "aws-amplify";
import { Provider } from 'react-redux';
import store from './Store';
import MMApp from './NewLayout/MMApp';
import './App.scss';
import { BrowserRouter as Router} from 'react-router-dom'
Amplify.configure({
    ...config
  });
  
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store} >
  <Router>
 
  <MMApp />  
  </Router>
    
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
