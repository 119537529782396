import React, { useRef, useState } from 'react';
// import { makeStyles } from '@mui/styles';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  Box,
  Paper,
  FormHelperText
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FirstStep from '../../NewLayout/Components/Steps Form/FirstStep';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handelIncrementStep, handelDecrementStep,  handelResetStep,getOTP, handelNewPassword, handelConfirmPassword, handelErrors } from '../../Store/Slice_reducer/ForgetPasswordSlice';
import SecondStep from '../../NewLayout/Components/Steps Form/SecondStep';
import { Auth } from 'aws-amplify';

function getSteps() {
  return ['Step 1', 'Step 2', 'Step 3'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <FirstStep />
    case 1:
      return <SecondStep />
    case 2:
      return (
       <PassWordInputField />
        
      );
    default:
      return 'Unknown step';
  }
}

export default function ResetPassword() {
  //  const classes = useStyles();
  const pageState=useSelector((state)=>state.forgetPassWordPageData)
  const dispatch=useDispatch()
  console.log("pageState", pageState)
  // const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const activeStep=pageState.activeStep
  console.log("activeStep", activeStep)
  
  const naviagate=useNavigate();
  const handleReset = () => {
    // setActiveStep(0);
    dispatch(handelResetStep())
    naviagate('/login')
  };
  
  
  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // if(pageState.userName){
    //   dispatch(handelIncrementStep())
    // }
    console.log("step next finish func")
    dispatch(handelIncrementStep())
    
  };


  const handelStep0=()=>{
    console.log("step 0 func")
    if (pageState.userName.length < 4) {
      dispatch(handelErrors({userNameError:'Username must be at least 4 characters long.'}))
      // setErrorMessage('Username must be at least 4 characters long.');
    } else if (pageState.userName.includes(' ')) {
      dispatch(handelErrors({userNameError:'Username must be at least 4 characters long.'}))
      // setErrorMessage('Username cannot contain spaces.');
    } else {
      // submit form
      dispatch(getOTP(pageState.userName))
      // dispatch(handelIncrementStep())
    }


    // if(!pageState.userName){
    //   //  Add error object
    //   dispatch(handelErrors())
    // }else{
    //   dispatch(handelIncrementStep())
    // }
    
// generateOTP
  }
  const handelStep1=()=>{
    console.log("step 1 func")
    dispatch(handelIncrementStep())
// store otp value
  }
  const handelStep2=(v)=>{
    console.log("step 2 func")
    if (pageState.newPassword !== pageState.confirmPassword) {
      // Display an error message or highlight the fields as invalid
      dispatch(handelErrors({passwordError:"Password not matched"}))
      return;
    }else if(!pageState.newPassword || !pageState.confirmPassword ){
      dispatch(handelErrors({passwordError:"Please enter new password"}))
    }else{
      // SubMIT form 
      const {userName, newPassword, OTP}=pageState
      Auth.forgotPasswordSubmit(userName, OTP, newPassword).then((data)=>{
           console.log('data',data);
           handleReset()
           
      }).catch((err)=>{
        console.log("err", err)
      })
    }
  

    // Make request to update password
  }

  const generateHandler=()=>{
    if(activeStep===0){
      return handelStep0
    }else if(activeStep===1){
      return handelStep1
    }else if(activeStep===2){
      return  handelStep2
    }else{
      return handleNext
    }

  }
  const buttonStepHandelr=generateHandler();
  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    dispatch(handelDecrementStep())
  };

  

  return (
    
      <Box className="forgetPassword_container">
        <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} className='paper-container'>
        <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box className="resetPassword_form_container " sx={{width:"100%"}}>
        
  
        {activeStep === steps.length ? (
          <div>
            <Typography >
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset}  >
              Go to Login page
            </Button>
          </div>
        ) : (
          <div className='input_container'>
            {getStepContent(activeStep)}
            <div className='resetPassword_button'>
              <Button disabled={activeStep === 0} onClick={handleBack} >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={buttonStepHandelr} >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
             
            </div>
            <Box sx={{marginTop:"10px"}}>
              <Link to="/Login" onClick={()=>{dispatch(handelResetStep())}}>
            <Button sx={{backgroundColor:"#00AAE3"}}  variant="contained" >Go Back</Button>
            </Link>
            </Box>
            
          </div>
        )}
    
      </Box>
        </Paper>
      </Box>

  );
}





const PassWordInputField=()=>{
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const confirmPasswordRef=useRef()
  // confirmPasswordRef.addEventListener('paste', (event) => {
  //   event.preventDefault();
  // });
  const pageState=useSelector((state)=>state.forgetPassWordPageData)
  const passwordError=pageState.formErrors.passwordError;
const dispatch=useDispatch();
 return(

  <Box className='password_container'>
  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            className='password-text-input'
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onChange={(event)=>{dispatch(handelNewPassword(event.target.value))}}
            label="Password"
            error={passwordError.length>0}
            
          />
        </FormControl>
         <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
         <InputLabel htmlFor="outlined-adornment-password"> Confirm Password</InputLabel>
         <OutlinedInput
           id="outlined-adornment-password"
           type={showPassword ? 'text' : 'password'}
           className='password-text-input'
           endAdornment={
             <InputAdornment position="end">
               <IconButton
                 aria-label="toggle password visibility"
                 onClick={handleClickShowPassword}
                 onMouseDown={handleMouseDownPassword}
                 edge="end"
               >
                 {showPassword ? <VisibilityOff /> : <Visibility />}
               </IconButton>
             </InputAdornment>
           }
           onChange={(event)=>{dispatch(handelConfirmPassword(event.target.value))}}
           label="Confirm Password"
           error={passwordError.length>0}
           inputRef={confirmPasswordRef}
         />
       </FormControl>
       
       <Typography>{passwordError}</Typography>
       </Box>
 )
      }


