import React, { useEffect, useState } from 'react'
import { API,Auth } from 'aws-amplify';
import CampaignsMock from '../../../Mock/publisherCampaigns.json'
import{getHyphenValues} from '../../../Utils/utils';
const TableBody = ({bodyData, campaignData}) => {
  
    console.log("Table body data", campaignData)
const renderTableBody=()=>{


//  px-6 py-4 
    return campaignData.map((bd)=>
    (
        <tr>
      
        <td className="px-6 py-4   text-sm font-medium text-gray-800 whitespace-nowrap campaignId text-center">
           {bd.SORTID}
        </td>
        <td className="px-6 py-4   text-sm text-gray-800 whitespace-nowrap campaignId text-center">
           {bd.campaignname}
        </td>
        <td className="px-6 py-4  text-sm text-gray-800 whitespace-nowrap publisherID text-center">
            {bd.publisherID}
        </td>
        <td className="px-6 py-4  text-sm font-medium  whitespace-nowrap groupName text-center">
            {getHyphenValues(bd?.publisherName)}
        </td>
       
    </tr>

)
    )
}


    return (
        <tbody className="divide-y divide-gray-200">
          {renderTableBody()}
        </tbody>
    )
}

export default TableBody




const SwitchComponent=({status, campaignData})=>{
    const [campaignStatus,setStatus]=useState()
    useEffect(()=>{
        setStatus(status==="Active"?true:false)
    },[])
    const handelSwitchStatus=async(e)=>{
        console.log("SWITCH ::: checked value",  e.target.checked)
        // console.log("SWITCH ::: value",  e.target.value)
        console.log("Campaign data",campaignData)
        setStatus(e.target.checked)
        // console.log("camapignStaus", campaignStatus)
        const updatedStatus=e.target.checked?"Active":'Inactive'
        await updateCampaign(campaignData,updatedStatus)
    }
  const   updateCampaign=async (item,updatedStatus)=> {
        delete item.updatedOn
        const {
          idToken: { jwtToken },
        } = await Auth.currentSession();
        const myInit = {
          headers: {
            "X-COG-KEY": jwtToken,
          },
          queryStringParameters: {
            SORTID:item.SORTID,
            status: updatedStatus
          }
        };
  console.log("MY in it", myInit)
  const response=await API.put("updateCampaignStatus", "campaign", myInit)
    console.log("response", response)
        // API.put("MerchantData", "campaign", myInit).then((response) => { 
        //   this.$store.commit("snackbar/showMessage", {
        //     content: "Campaign has been succesfully updated!"
        //   });
        // });
      
      }
    //   checked={campaignStatus}

    return (
       <>

<label class="relative inline-flex items-center cursor-pointer">
  <input type="checkbox" value={campaignStatus} class="sr-only peer"  onClick={handelSwitchStatus} id={campaignData.campaignId} checked={campaignStatus} />

  <div class="w-12 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-mm-blue after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-mm-green"></div>
 
</label>
</>
    )
}

