import { configureStore } from '@reduxjs/toolkit';
import DashboardSlice from './Slice_reducer/DashboardSlice';
import publisherCampaignsSlice from './Slice_reducer/publisherCampaignSlice' 
import sideNavSlice from './Slice_reducer/sideNavSlice';
import UserSlice from './Slice_reducer/UserSlice';
import ForgetPasswordSlice from './Slice_reducer/ForgetPasswordSlice';
export default configureStore({
  reducer:{
    sideNavData:sideNavSlice,
    pubCampData:publisherCampaignsSlice,
    pubReportsData:DashboardSlice,
    userData:UserSlice,
    forgetPassWordPageData:ForgetPasswordSlice
  } ,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});