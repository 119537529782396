import { createSlice } from '@reduxjs/toolkit';

export const sideNavSlice = createSlice(
    {
  name: 'sideNavData',
  initialState: true
  ,
  reducers: {
    handelsideNav: (state, action) => {
      return state=action.payload

      ;
  },
}});

// this is for dispatch
export const { handelsideNav } = sideNavSlice.actions;

// this is for configureStore
export default sideNavSlice.reducer;
