// import Home from "../Components/Home";
import PubCampaigns from "../NewLayout/View/Publisher/Campaigns";
import MM_PublisherReports from "../NewLayout/View/Publisher/MM_PublisherReports";
// import Publisher from "../view/Publisher";
// import PublisherCampaigns from "../view/Publisher/Campaigns";
// import { Reports } from "../view/Publisher/Reports";

// export const appRoutes=[
//     {
//         path:'/',
//         element:Home,
//         exact:true
//     },
//     {
//         path:'/publisher',
//         element:Publisher,
//         exact:true
//     },
//     {
//         path:'/publisher/:id',
//         element:Publisher,
//         exact:true
//     },
//     {
//         path:'/publisher-campaign',
//         element:PublisherCampaigns,
//         exact:true
//     },
//     {
//         path:'/publisher-Report',
//         element:Reports,
//         exact:true
//     },
   
// ]



export const mmRoutes=[
    {
        path:'/',
        element:MM_PublisherReports,
        exact:true
    },
    
    {
        path:'/reports',
        element:MM_PublisherReports,
        exact:true
    },
    {
      path:'*',
      element:MM_PublisherReports,
      exact:true
  },
  {
    path:'/campaigns',
    element:PubCampaigns,
    exact:true
  },
  ]