import { Box, Grid, Paper, Skeleton } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import MultipleSelectCheckBox from '../Multi select checkbox/MultiSelectCheckbox'
import { getPubClickToLeadsReports, getPubDashboardClicksToLeadTableReports, } from '../../../Service/Publisher';
import DateRangePicker from '../../../Components/Layout/DatePicker/DateRangePicker';
import ReportsTable from '../Table/ReportsTable';
import ClicksToReportJson from "../../../Mock/Required JSON/Clicks-to-lead.json"
import { mBrandIds } from '../../../Mock/mBrandData';

// import {  options2 } from '../../../Mock/BarChartData';
import { Bar } from 'react-chartjs-2';
import moment from 'moment/moment';

import CircularLoader from '../../../Components/Layout/Loader';
import { createBrandsBarDatasets, clicksToLeadOptions, createBarChartJsonForBrand } from '../../../Utils/chartsDs';
import { PUBLISHER_ID } from '../../View/Publisher/MM_PublisherReports';
import { modifyClicksToLeadTableData } from '../../../Utils/utils';

export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

// const brandDropdownMenus=mBrandIds.map((brand)=>brand.label);
export const brandDropdownMenusData = createBrandsBarDatasets(mBrandIds)
const sortedBrandDD = brandDropdownMenusData.sort((a, b) => {
  const labelA = a.label.toUpperCase();
  const labelB = b.label.toUpperCase();

  if (labelA < labelB) {
    return -1;
  }

  if (labelA > labelB) {
    return 1;
  }

  return 0;
});
export const brandDropdownMenus = sortedBrandDD.map(item => item.label)
const ClicksToLeadReport = (props) => {

  //  const dispatch=useDispatch();
  //  const rawDataReport=useSelector((state)=>state.pubReportsData.rawDataReport)
  // console.log("props", props); 
  // console.log("brand dd menu", brandDropdownMenus)
  const { dropDownMenus, publisher_id } = props
  const [clicksToLeadResponse, setclicksToLeadResponse] = useState([])
  const [barChartData, setBarCharts] = useState(ClicksToReportJson.chartData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  // const [chartXlabel, setChartXlabel] = useState(["jan", "feb", "mar"]);

  const [tableData, setTableData] = useState([])

  const [dateValue, setDateValue] = useState({
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  })
  const [campaignsSelected, setCampaignsSelected] = useState(dropDownMenus.dashboardCampaigns.map(item => item.label))
  const [brandSelected, setbrandSelected] = useState(brandDropdownMenus)
  const selectedCampaigns = dropDownMenus.dashboardCampaigns.filter(option => campaignsSelected.includes(option.label));
  const selectedBrandIds = brandDropdownMenusData.filter(option => brandSelected.includes(option.label));

  const [campaignsDDError, setCampaignDDError]=useState("")
  const [mBrandDDError, setmBrandDDError]=useState("")
  console.log("brandDropdownMenusData", brandDropdownMenusData)
  // console.log("Selected Brands", selectedBrandIds)
  const fetchReports = async (queryParams) => {
    setLoading(true)
    try {

      const [response, groupByDateRes] = await Promise.all([
        getPubClickToLeadsReports(queryParams),
        getPubDashboardClicksToLeadTableReports(queryParams)
      ])
 

      const formatTableData = response.tableContent.map((item) => {
        //  const newItem={...item};
        var momentDate = moment.utc(item['Date']);
        const newRow = {
          "Date": momentDate.format("MM-DD-YYYY"),
          "Commission": `$${item["Total Commission"].toFixed(2)}`,
          "campaign": item["campaignName"],
          "brandName": item['mBrandName'],
          "brandId": item["mBrandID"],
          "CLICKS": item['CLICKS'],
        }

        return newRow
      })

      // const updateTable=groupByDateRes.tableContent.map((item, index)=>{
      //   var momentDate = moment.utc(item['Date']);
      //  const newRow={
      //   "Date": momentDate.format("MM-DD-YYYY"),
      //   "Commission":`$${item["Total Commission"].toFixed(2)}`,
      //   "CPC":`$${item["CPC"].toFixed(2)}`,
      //   "Adrequests":item['Requests'],
      //   "CLICKS":item['CLICKS'],
      //   "Leads":item['leads'],
      //   "CTR": `${item["CTR"].toFixed(2)}%`,
      //   "CTL": item["CTL"] ? `${item["CTL"].toFixed(2)}%`:0 ,
      //  }
      //  return newRow
      // })


      console.log("Table conteent format", formatTableData)

      const modified = modifyClicksToLeadTableData(groupByDateRes.tableContent)

      console.table(modified)

      const converteBrandCharts1 = createBarChartJsonForBrand(formatTableData, brandDropdownMenusData)

      setBarCharts(converteBrandCharts1)
      formatTableData.forEach(item => {
        delete item.brandId
        delete item.campaign
        delete item.brandName

      })
      setclicksToLeadResponse(response)
      setTableData(modified)
      setLoading(false)
    } catch (err) {
      setError(err)
      console.log("Error", err)
      setLoading(true)
    }
  }
  const debounceOnChange = React.useCallback(debounce(fetchReports, 700), []);




  useEffect(() => {
    const queryParams = {
      startDate: moment(dateValue.startDate).format("YYYY-MM-DD"),
      endDate: moment(dateValue.endDate).format("YYYY-MM-DD"),
      mPubID: publisher_id,
      mPubCampaignID: selectedCampaigns.map(camp => camp.value).join(','),
      mBrandId: selectedBrandIds.map(camp => camp.value).join(',')
    }
    const { startDate, endDate } = dateValue;



    console.log('formatted  queryParams', queryParams);
 const {mBrandId, mPubCampaignID}=queryParams

    if (startDate && endDate &&  mPubCampaignID &&  mBrandId) {
      debounceOnChange(queryParams);
    }

  }, [dateValue.startDate, dateValue.endDate, campaignsSelected, brandSelected])

  // rawDataReport.selectedCampaigns, rawDataReport.selectedAreaStudy, debounceOnChange
  const handelDateChange = React.useCallback((newValue) => {
    // dispatch(handelDatePicker({type:"raw", data:newValue}))
    setDateValue(newValue)
  }, [])



  const handleCampaignsDropdowns = (value) => {
    setCampaignsSelected(value)
    if (value.length === 0){
      setCampaignDDError("Please select any one campaign")
    }else {
      setCampaignDDError("")
    }
  }






  const handelMbrandIdDropdown = (value) => {
  setbrandSelected(value)
    if (value.length === 0){
      setmBrandDDError("Please select any one brand")
    }else {
      setmBrandDDError("")
    }
  }


  return (

    <Box className='px-0'>


      <Grid container spacing={3}>
        <Grid item xs="12" md="4">
          <DateRangePicker dateValue={dateValue} onChangeValue={handelDateChange} />
        </Grid>
        <Grid item xs="12" md="4">
          <MultipleSelectCheckBox menuData={dropDownMenus.dashboardCampaigns.map(item => item.label)} menuLabel="Campaigns" menuSelected={campaignsSelected} menuHandler={handleCampaignsDropdowns}   isError={campaignsDDError.length>0}  errorMessage={campaignsDDError} />
        </Grid>
        <Grid item xs="12" md="4">
          <MultipleSelectCheckBox menuData={brandDropdownMenus} menuLabel="Brands" menuSelected={brandSelected} menuHandler={handelMbrandIdDropdown}  isError={mBrandDDError.length>0}  errorMessage={mBrandDDError}/>
        </Grid>
      </Grid>


      <>
        {
          loading ? <Box my={5}><Skeleton variant="wave" height={'50vh'} /></Box> :
            <Box my={5}>
              <Paper className='chart-box'>
                <Bar data={barChartData} options={clicksToLeadOptions} dataSetGroup="revenue" />
              </Paper>


              {/* <ReportsTable  tableBodyContent={{data:clicksToLeadResponse}}/> */}
            </Box>
        }
        {loading ? <Box my={5}><Skeleton variant="wave" height={'50vh'} /></Box> :
          <Box my={5}>
            <Paper className='table-box-reports'>
              <ReportsTable tableContent={tableData} />
            </Paper>

          </Box>
        }
      </>




    </Box>

  )
}

export default ClicksToLeadReport