import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateOtpService } from "../../Service/Auth";

export const getOTP = createAsyncThunk("MM/ResetUserPassword", async (userInfo) => {
  console.log("user otp ", userInfo)
  let response = await generateOtpService(userInfo)
  return response;
  //det som returneras här, kommer att bli vår action.payload
});

const INITIAL_STATE= {
  user: {

  },
  confirmPassword:"",
  newPassword:"",
  userName: '',
  loading: false,
  check: true,
  error: {},
  activeStep: 0,
  formErrors: {
    userNameError: "",
    passwordError: ""
  },
  OTP: ''
}


const userForgetPasswordSlice = createSlice({
  name: "Auth module",
  initialState:INITIAL_STATE,
  reducers: {
    handelLogout: (state, action) => {
      return { ...state, user: {}, error: {}, loading: false }
    },
    storeUserInfo: (state, action) => {
      return { ...state, user: action.payload }
    },
    clearErrorMessage: (state, action) => {
      return {
        ...state, error: {}, user: {}, loading: false
      }
    },

    handelIncrementStep: (state) => {
      return {
        ...state, activeStep: state.activeStep + 1
      }

    },
    handelDecrementStep: (state) => {
      return {
        ...state, activeStep: state.activeStep - 1
      }

    },
    handelResetStep: (state) => {
      
      return {
        ...INITIAL_STATE
      }

    },

    handelOTP: (state, action) => {
      return {
        ...state, OTP: action.payload,
      }
    },

    handelUserName: (state, action) => {
      return {
        ...state, userName: action.payload
      }
    },

    handelUserNameErrors: (state, action) => {
       const formError=state.formErrors
       formError.userNameError=action.payload
      return {
        ...state, formErrors:formError
      }
      // console.log("action", action)
    },
    
    handelErrors: (state, action) => {
      const formError=state.formErrors
      // formError.passwordError=action.payload
    
      return {
       ...state, formErrors:{...action.payload}
     }
     // console.log("action", action)
   },
    handelNewPassword:(state, action)=>{
  return {
    ...state, newPassword:action.payload
  }
    },
    handelConfirmPassword:(state, action)=>{
      return {
        ...state, confirmPassword:action.payload
      }
        }




  },
  extraReducers: {
    [getOTP.fulfilled]: (state, action) => {
      //If we have to totally replace the existing array:
      // state.todos = action.payload;

      //if we want to add the json to an existing array

      let updateUser = { ...state.user };
      updateUser = action.payload
      state.user = updateUser;

      state.loading = false;
      state.activeStep++
    },
    [getOTP.pending]: (state) => {
      state.loading = true;
    },
    [getOTP.rejected]: (state, action) => {
      console.log("error in redux thunk", action)
      state.error = action.error
      state.loading = false;
    }
  }
});

export default userForgetPasswordSlice.reducer;
// this is for dispatch
export const { handelLogout, storeUserInfo, clearErrorMessage, handelIncrementStep, handelDecrementStep, handelResetStep, handelUserName, handelUserNameErrors,handelOTP, handelNewPassword, handelConfirmPassword, handelErrors} = userForgetPasswordSlice.actions;
