import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
// import { makeStyles } from '@mui/styles';
import { FormHelperText, ListItemIcon } from '@mui/material';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: 'menu'
};

const names = [
  'Prod_Publisher_Camp',
  'New Prod Publisher Camp1',
  'New Prod Pub Camp',
 
];
const classes = {
  indeterminateColor: {
        color: "#f50057"
      },
      selectAllText: {
        fontWeight: 500
      },
      selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
      },
      menuItem:{
        padding:""
      }

}
export default function MultipleSelectCheckBox({menuData, menuLabel, menuHandler,menuSelected, isError, errorMessage}) {


  // console.log("Menu selected ", menuSelected)
  const [selected, setSelected] = React.useState([]);
  // const isAllSelected =
  //   menuData.length > 0 && selected.length === names.length;
    const isAllSelected =
    menuData.length > 0 && menuSelected.length === menuData.length;

  const handleChange1 = (event) => {
    const value = event.target.value;
    // console.log("Value", value)
    if (value[value.length - 1] === "all") {
    
         menuHandler(menuSelected.length === menuData.length ? [] : menuData)
        // setSelected(selected.length === names.length ? [] : names);
        // setSelected(selected.length === menuData.length ? [] : menuData);

        // menuHandler(selected.length === names.length ? [] : names)
      return;
    }
    // setSelected(value);
     menuHandler(value)
  };


  return (
    <div>
      <FormControl sx={{  width: "100%" }} error={isError} >
        <InputLabel id="demo-multiple-checkbox-label">{menuLabel}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={menuSelected}
          
          onChange={handleChange1}
          input={<OutlinedInput label={menuLabel}/>}
          renderValue={(selected) => 
          { 
            if (isAllSelected){
              return "All Selected"
            }else{
              return selected.join(", ")
            }
          }}
          MenuProps={MenuProps}
        >
              <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : ""
          }}
        >
          <ListItemIcon className='checkBoxIcon'>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                menuSelected.length > 0 && menuSelected.length < menuData.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem>
          {menuData.map((name) =>
 {                  
   return    <MenuItem key={name} value={name} className='checkBoxIcon' >
              <Checkbox checked={menuSelected.indexOf(name) > -1}  />
              <ListItemText primary={name} />
            </MenuItem>
}
          
          )}
        </Select>
        {
          isError && <FormHelperText>{errorMessage}</FormHelperText>
        }
        
      </FormControl>
    </div>
  );
}


// variantName.findIndex((item) => item.id === variant.id) >= 0


// campaignOptions.findIndex(item=>item.value===a.value)